import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function SpeakerCard({ speaker, index, setOverlay, overlay }) {
  const size = "23rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: size ? "28rem" : "24rem",
      }}
      className='rounded-0'
    >
      <Container fluid className='p-0'>
        <img
          alt='...'
          className='img rounded-0 img-responsive'
          width='100%'
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container
        fluid
        style={{
          borderRadius: "0 0 10px 10px",
          background: "#fff",
          zIndex: 10,
          minHeight: "100px",
          position: "absolute",
          bottom: 0,
        }}
      >
        <Row className='text-i'>
          {speaker.companyImage ? (
            <>
              <Col xs={9} className='mt-2'>
                <h4
                  className='d-inline text-700-a text-i'
                  // style={{ fontSize: size ? "1.2rem" : "1.5rem" }}
                >
                  {speaker.name}
                </h4>
              </Col>
              <Col xs={3} className='p-0'>
                <img
                  alt='...'
                  className='border border-warning img-no-padding img-responsive'
                  src={require(`assets/images/speakers/${speaker.companyImage}`)}
                  style={{
                    marginTop: "-50px",
                    borderRadius: 0,
                    maxWidth: "80px",
                  }}
                />
              </Col>
            </>
          ) : (
            <Col xs={12} className='mt-2 text-center'>
              <h6
                className='d-inline text-700-a text-i'
                style={{ fontSize: size ? "1.2rem" : "0.9rem" }}
              >
                {speaker.name}
              </h6>
            </Col>
          )}
        </Row>
        <Row>
          <Col lg={12} className='p-0 text-dark pl-1'>
            {size ? (
              <>
                <h5 className='m-2  text-300-a'>
                  {speaker.title}
                  <br />
                </h5>
                {speaker.company && (
                  <h5 className='p-1 text-700-a'>{speaker.company}</h5>
                )}
              </>
            ) : (
              <h6 className='m-2 text-center text-300-a'>
                {speaker.title}
                <br />
                {speaker.company && (
                  <span className='p-1 text-700-a'>{speaker.company}</span>
                )}
              </h6>
            )}
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function SpeakerCardMobile({ speaker, index, setOverlay, overlay }) {
  const size = "23rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "17rem",
      }}
      className='rounded-0'
    >
      <Container fluid className='p-0'>
        <img
          alt='...'
          className='img rounded-0 img-responsive'
          width='100%'
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className='mt-2 px-1'>
            <h6
              className='d-inline text-i text-900'
              style={{ textTransform: "none" }}
            >
              {speaker.name}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className='text-dark text-left px-1'>
            <h6
              className='mt-1 text-700'
              style={{ fontSize: "10px", textTransform: "none" }}
            >
              {speaker.title}
            </h6>
            <h6 className='text-700' style={{ textTransform: "none" }}>
              {speaker.company && <span>{speaker.company}</span>}
            </h6>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function Speaker({ speakers }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [overlay, setOverlay] = React.useState([speakers[0], 0, false]);

  return (
    <>
      <div
        style={{
          background: "rgb(230,40,125)",
          backgroundImage:
            "linear-gradient(167deg, rgba(230,40,125,1) 21%, rgba(201,168,72,1) 89%)",
        }}
        className='section'
      >
        <Container>
          <Row>
            <Col lg={12} className={"mx-auto"}>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Container fluid>
                      <Row>
                        {speakers.map((s, i) => (
                          <Col lg={4} md={6} xs={6} key={i} className='p-1'>
                            {size ? (
                              <SpeakerCard
                                speaker={s}
                                index={i}
                                overlay={overlay}
                                setOverlay={setOverlay}
                              />
                            ) : (
                              <SpeakerCardMobile
                                speaker={s}
                                index={i}
                                overlay={overlay}
                                setOverlay={setOverlay}
                              />
                            )}
                          </Col>
                        ))}
                      </Row>
                      <Row>
                        <Col lg={12} className='text-center'>
                          <Button
                            href='/speakers'
                            className='btn my-2 text-center px-5 mr-2'
                            color='primary'
                            size='lg'
                          >
                            View All
                          </Button>
                          <Button
                            href='/past-speakers'
                            className='btn my-2 text-center px-5'
                            color='primary'
                            size='lg'
                          >
                            PAST SPEAKERS
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
