import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';

function WhyAttend() {
	return (
		<div
			style={{
				backgroundColor: '#fff',
				// backgroundImage: 'url(' + require('assets/images/backgroundSection.jpg') + ')',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				
			}}
			className="section py-3"
			data-parallax={true}
		>
			<Container>
				<Row>
					{/* <Col xs={12} className="p-0">
						<h1 className="text-center text-400 pb-0 text-primary">
							WHY{' '}
							<span className="text-700" style={{ color: '#000' }}>
								<b>GHANA</b>
							</span>
						</h1>
					</Col> */}
					<Col lg={12}>
						<div>
							<h5
								className="	text-400"
								style={{
									letterSpacing: 0,
									lineHeight: '20px',
									color: '#010011',
									textTransform: 'none',
								}}
							>
								<br />
								<br />
								Ghana’s Financial Services sector has attracted a lot of attention in the last few
								years. Beginning with the Banking and Financial Services Sector, the Regulator (Bank of
								Ghana), took steps not only to address the risks in the sector but also prepare existing
								banks for the growth expected in the Ghanaian economy.
								<br /> <br />
								2018 and 2019 can be characterised as challenging and eventful years for Ghana’s BFSI as
								banks and FIs have had to deal with several reforms including full implementation of the
								minimum capital requirement, new capital requirement directive, revocation of banking
								licences, as well as intensified competition from non-traditional competitors, such as
								FinTechs and Telecommunication companies.
								<br />
								<br />
								With everything that the industry has witnessed through the past two years, 2020 will
								bring in a phase of transformation which will be aided by the adoption and deployment of
								innovative technology. With its massive unbanked population, West Africa is in a unique
								position to offer lucrative opportunities for digital disruption. It is home to one of
								the 3 fastest growing Economies in Africa and has been striving to drive Financial
								Inclusion and Sustainable Banking Practices.
								<br />
								<br /> Fintech firms and banks are transforming, as they strive to address consumer
								demands for timely and convenient consumption of services. Technology has triggered a
								transformation in policies and regulations, compelling banks and financial institutions
								to work on their strategies and push the boundaries when it comes to technology.
								<br />
								<br />
								With this as a driver we at Exibex are proud to host{' '}
								<b>Finnovex West Africa in Accra Ghana on the 14th and 15th of July, 2020.</b>
							</h5>
						</div>
					</Col>
				</Row>

				<Col lg={12} className="text-center">
					<Button href="/register" className="btn my-2 text-center px-5" color="primary" size="lg" outline>
						Register Now
					</Button>
				</Col>
			</Container>
		</div>
	);
}
export default WhyAttend;
