import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";

import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";

function Speaker({ speakers }) {
  const [step, setStep] = useState(1);
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#020829",
          overflow: "hidden",
        }}
        className='section pt-0'
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container className='py-5'>
            <Row>
              <Col lg={6}>
                <h2 className='text-700 section-title text-g'>
                  BE OUR <i>SPONSOR</i>
                </h2>

                <p className='text-white'>
                  If you are launching a new product or solution aligned with
                  one of the themes below, or have a proven track record in this
                  area with experience to share - we want to hear from you:
                </p>
              </Col>
              <Col lg={6}>
                <h2 className='text-700 section-title text-g'>
                  SPONSORSHIP <i>BENEFITS</i>
                </h2>

                <p className='text-white'>
                  Sponsorship at Finnovex Europe comes in many shapes and sizes.
                  We’ll enhance your experience by customizing and helping you
                  choose the option that’s right for you and your brand. Our end
                  goal is to put your brand in the spotlight and above
                  competition..
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={12} className='my-5'>
                <h3 className='text-white text-700'>
                  We offer wide range of partnership packages that gives you the
                  opportunity to showcase your solutions and Achieve your
                  regional business development goals. Our customized packages
                  include:{" "}
                </h3>
              </Col>
              {content.map((s) => (
                <Col key={s.title} lg={6} xs={6} className='text-center'>
                  <ScrollAnimation
                    animateIn={"fadeInDown"}
                    animateOnce={true}
                    duration={1.5}
                  >
                    <hr />
                    <img
                      src={require(`assets/images/icons/${s.image}`)}
                      width='100'
                      alt='icon'
                    />

                    <h5 className='text-white text-700 mt-2'>{s.title}</h5>
                    <h5 className='text-white text-400 mt-2'>
                      {s.description}
                    </h5>
                  </ScrollAnimation>
                </Col>
              ))}
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12} className='mt-5 text-center bg-white py-3'>
                <h3 className='text-center text-i mt-0'>
                  DO YOU HAVE A PRODUCT OR SERVICE THAT OUR SENIOR
                  DECISION-MAKERS AND INFLUENCERS NEED?
                </h3>
                <br />

                <h5 className='text-400 mt-0'>
                  Sponsorship puts your brand in the spotlight. For more
                  information, write to us{" "}
                  <a href='mailto:info@exibex.com' className='text-primary'>
                    <b>info@exibex.com</b>
                  </a>{" "}
                  ! or call us on{" "}
                  <a href='tel:+971 585518437' className='text-primary'>
                    <b>+971 585518437</b>
                  </a>
                  /
                  <a href='tel:+91 9980028128' className='text-primary'>
                    <b>+91 9980028128</b>
                  </a>{" "}
                  One of our team will be in touch within 24 hours!
                </h5>

                <br />
                {/* <Container>
                    <Row className="justify-content-center">
                      <Col lg={4}>
                        <Button
                          href="/sponsors"
                          className="btn my-2 text-center px-5"
                          color="primary"
                          size="lg"
                          outline
                        >
                          Contact Us
                        </Button>
                      </Col>
                    </Row>
                  </Container> */}
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const content = [
  {
    title: "Opportunity for Link-Ups with Top Decision Makers in Attendance",
    description: `The event will bring together over 250+ delegates from top banks and financial institutions. Engage and influence the people who matter the most when it comes to making the real decisions.
    `,
    image: "decision.png",
  },
  {
    title: "Speak to a Select Audience Sect",
    description: `Our extensive and precise delegate research ensures you the opportunity to meet relevant stakeholders. Network with industry leaders as they lay out their transformational goals!
    `,
    image: "competition.png",
  },
  {
    title:
      "Bundle the latest Financial Marketing ideas, Actionable Insights and Networking with Peers",
    description: `We bring together specialists and experts in banking, digital transformation and customer experience, to discuss, deliberate and share the best practices prevalent in the industry.
    `,
    image: "one.png",
  },
  {
    title: "Retrieve Knowledge from the Best in the Business",
    description: `We present only the BEST of the best within the industry at Finnovex. Witness first-hand, the latest and cutting edge insight offerings and products/solutions that will be showcased by our partners.`,
    image: "showcase.png",
  },
  {
    title: "Stand Tall Among Competitors",
    description: `
    As the  banking landscape is evolving at a the speed of light,  be aware that your competitors are exploring new ideas and innovations that will crush the status quo. Financial Leaders have to stay sharp to keep their institution pertinent and competitive in this fast-changing environment. Join us to learn new Fintech digital strategies, by knowing - what’s working, what’s not and how to transform your organization’s strategy and marketing for a sustained growth. 
    `,
    image: "decision.png",
  },
  {
    title: "Leverage from our Experience",
    description: `
    Access the following -  15,0000+ contacts from all our past 10 editions of Finnovex Global Series; online/offline Marketing Channels; Network with creative and inspired people, to aid your strategy formation needed for growth as you Gain from our brand strategy!

    `,
    image: "competition.png",
  },
];
