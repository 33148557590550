import React from "react";
import { Link } from "react-router-dom";

function ProfilePageHeader({ title, background }) {
  let pageHeader = React.createRef();
  return (
    <>
      <div
        style={{
          background: "#5a2169",
          backgroundImage: "url(" + require(`assets/images/bg5.png`) + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {title ? (
          <>
            <h1
              className={"text-center text-white text-700 mt-0"}
              style={{ zIndex: 10 }}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <h6 className={"text-center text-white text-700 mt-0"}>
              <Link to="/" className={"text-center text-primary text-700 mt-0"}>
                HOME
              </Link>{" "}
              / <span dangerouslySetInnerHTML={{ __html: title }} />
            </h6>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ProfilePageHeader;
