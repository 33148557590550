import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";
import { Progress } from "reactstrap";

function Stats() {
  let pageHeader = React.createRef();
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "url(" + require(`assets/images/bg4.png`) + ")",
          // background: "#5a2169",
          backgroundAttachment: "fixed",
          backgroundColor: "rgba(255,255,255,0.8)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflow: "hidden",
        }}
        data-parallax={true}
        ref={pageHeader}
      >
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
          className='section'
        >
          <Container>
            <Row>
              <Col lg={12} className='mb-4'>
                <h1 className='text-center pb-0 text-i mt-0-'>
                  <span className='text-700'>KEY HIGHLIGHTS</span>
                </h1>
              </Col>
            </Row>
            <Row className='justify-content-center'>
              {content.map((c, i) => (
                <Col lg={3} xs={6} key={i} className='text-center p-3'>
                  <img
                    src={require(`assets/images/spotlight/${c.image}.png`)}
                    width='70'
                    alt='icon'
                  />

                  <h5 className='text-700 text-dark mt-2'>{c.title}</h5>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Stats;

const content = [
  // { title: "Participating Companies", value: 100, image: 32, plus: true },
  { title: "250+ VIP Attendees", image: 1 },
  { title: "Notable Speaker Line-Up", image: 2 },
  { title: "Industry Actors and Policymakers Engagement", image: 3 },
  { title: "FinTechs and Innovators Trail Sessions", image: 4 },
  { title: "Intriguing Content Sessions", image: 5 },
  { title: "Deliberately Curated Agenda ", image: 6 },
  { title: "Virtual Interactive Networking ", image: 7 },
  { title: "Brainstorming Roundtables", image: 8 },
];
