import React from "react";

import { Container, Row, Col } from "reactstrap";

function Speaker({ speakers }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          // backgroundImage:
          // 	'linear-gradient(to bottom,#000000, #011122,#01162c,#001b37,#001f41,#00234c, #002654, #01285b,#042b63, #042e69,#053070, #063376,#07357d)',
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          overflow: "hidden",
        }}
        className='section'
      >
        <Container
          fluid
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container className='py-5'>
            <Row>
              <Col lg={11}>
                <h2 className=' text-g mb-4'>
                  GLOBALIZATION OF BANKING THROUGH TECHNOLOGY, REGULATION AND
                  COLLABORATION
                </h2>
              </Col>
            </Row>
            <Row>
              <Col lg={11}>
                <h5 className='text-white text-500'>
                  Fintech adoption throughout the European Region, especially in
                  countries such as the Netherlands, U.K., Germany, Sweden, and
                  Switzerland, are well above the global average of 64%, and
                  aren’t showing signs of slowing down any time soon! Exibex is
                  making a comeback within the region with{" "}
                  <b>FINNOVEX Europe Virtual Summit 2022.</b>
                  <br />
                  <br />
                  As European players within the financial system are under
                  threat from BigTechs and innovative players within the payment
                  space, the large traditional banks and payment service
                  providers are being forced to expand their service offering
                  and consolidate the summit coming through from
                  <b> May 10th -11th</b>
                  2022. Join the Virtual Stream online with 20+ Pioneer speakers
                  and 250+ HNI delegates, to witness 8+ Networking Hours, learn
                  from 4+ Panel Discussions and 15+ Keynote.
                </h5>
              </Col>
            </Row>
          </Container>

          <Container className='py-5'>
            <Row>
              {images.map((id, i) => (
                <Col lg={4} key={i}>
                  <img
                    alt='...'
                    className=' img-responsive my-3'
                    width='100%'
                    src={`https://drive.google.com/thumbnail?authuser=0&sz=w500&id=${id}`}
                  />
                </Col>
              ))}
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;

const images = [];
