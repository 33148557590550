import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";

function Sponsor({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  return (
    <div
      style={{
        backgroundColor: "#fff",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className='section section-with-space'
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <h1 className='text-center pb-0 text-i mt-0 text-uppercase'>
              2022 SPONSORS
            </h1>
          </Col>
        </Row>
        <Row>
          {Object.keys(sponsors).map((sponsor, index) => (
            <React.Fragment key={index}>
              <Col lg={sponsors[sponsor][0].a ? 6 : 12}>
                <h3
                  className='text-center text-muted pb-4'
                  style={{ fontSize: "30px" }}
                >
                  <b>{sponsors[sponsor].length > 1 ? `${sponsor}` : sponsor}</b>
                </h3>
                <Container fluid>
                  <Row>
                    {sponsors[sponsor].map((data, i) => (
                      <Col lg={3} className={"mx-auto"} key={i}>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={data.link}
                        >
                          <Card style={{ borderRadius: 0, boxShadow: "none" }}>
                            <img
                              src={require(`assets/images/sponsors/${data.image}`)}
                              className='mx-auto'
                              alt={data.name}
                              width='100%'
                              style={{ maxWidth: "195px", borderRadius: 0 }}
                            />
                          </Card>
                        </a>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </Col>
            </React.Fragment>
          ))}
          <Col lg={12} className='text-center'>
            <Button
              href='/partners'
              className='btn my-2 text-center px-5'
              color='info'
              size='lg'
            >
              View All
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sponsor;
