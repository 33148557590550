import React from "react";

import Navbar from "components/Navbar";
import Footer from "components/Footer";
import AwardPage from "components/AwardPage";
import AwardNominate from "components/AwardNominate";
import AwardWinners from 'components/AwardWinners';

import {} from "./content";

import ScrollUpButton from "react-scroll-up-button";

function About() {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <div
        style={{
          // backgroundColor: '#002245',
          backgroundImage:
            "url(" +
            require(`assets/images/${
              size ? "awardsbanner2.png" : "awardbanner2mobile.png"
            }`) +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: size ? "100%" : "auto 100%",
          backgroundPosition: "center",
          padding: size ? "13rem 0" : "8rem 0",
          marginTop: !size && "3.75rem",
        }}
        className="section"
        data-parallax={true}
      >
        <>
          <h1
            className={"text-center text-white text-700 mt-0"}
            style={{ zIndex: 10 }}
            dangerouslySetInnerHTML={{ __html: "" }}
          ></h1>
        </>
      </div>
      <AwardPage />
      <AwardNominate />
      <AwardWinners />
      <Footer />
    </div>
  );
}

export default About;
