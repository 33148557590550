import React from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  CardImg,
  CardFooter,
} from "reactstrap";

function Price() {
  //   const size =
  //     document.body.clientWidth >= 1024 ||
  //     document.documentElement.clientWidth >= 1024
  //       ? true
  //       : false;

  return (
    <>
      <div
        style={{
          background: "url(" + require(`assets/images/bg4.png`) + ")",
          // background: "#5a2169",
          backgroundAttachment: "fixed",
          backgroundColor: "rgba(255,255,255,0.8)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflow: "hidden",
        }}
        data-parallax={true}
      >
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
          className='section'
        >
          <Container>
            <Row className='justify-content-center'>
              <Col lg={6} className='text-center mb-5 '>
                <h2 className='text-700 text-center mt-5 mb-2 text-i'>
                  How Would this Virtual Summit Experiences Be Better?
                </h2>

                <br />
                <h5 className='text-600 text-dark'>
                  Finnovex Europe is back again with an upgraded twist via our
                  fully-virtual summit edition in the region bringing together
                  all experts of the Banking and Financial Services ecosystem
                  virtually and be the first of its kind to offer for top-notch
                  and valuable business connections, power-packed panel
                  discussions and insightful presentations, straight to your
                  homes or offices.
                </h5>
              </Col>
              <Col lg={6} className='align-self-center'>
                <Card
                  className='text-center mx-auto'
                  style={{ minHeight: "320px", maxWidth: "300px" }}
                >
                  <CardImg src={require("assets/images/online.png")} />

                  <CardFooter>
                    <Button
                      href='/hybrid-event'
                      className='btn'
                      color='primary'
                      size='lg'
                      style={{ width: "90%" }}
                    >
                      <p className='m-0 text-700'>Learn More</p>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row className='justify-content-center'></Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Price;
