import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      style={{
        backgroundColor: "#000",
        backgroundImage:
          "url(" + require("assets/images/backgroundSection.jpg") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="footer footer-black footer-white text-white"
    >
      <Container fluid>
        <Row>
          <Col lg={4} className="px-5 pt-5 pb-3">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <a href="/">
                    <img
                      src={require("assets/images/logo/eu.png")}
                      alt="vibe"
                      width="100%"
                      style={{ maxWidth: "300px" }}
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col
            lg={4}
            className="mx-auto px-5 py-3 "
            style={{
              borderLeft: "0.5px solid #dfdfdf",
              borderRight: "0.5px solid #dfdfdf",
            }}
          >
            <a href="/" className="mx-auto">
              <img
                src={require("assets/images/logo/exibex.png")}
                alt="exibex"
                width="50%"
                style={{ maxWidth: "200px" }}
              />
            </a>
            <h5 className="text-left text-700">CONTACT US</h5>

            <p className="text-left p-0 mt-0">
              Exibex FZ LLE
              <br />
              Office 1309,13th Floor, Creative Tower, Fujairah
              <br />
              United Arab Emirates
              <br />
              +44 20 3290 7867 | +9199800 28128
              <br />
              Email: info@exibex.com
            </p>
          </Col>

          <Col lg={4} className="px-5 py-3">
            <h3 className="text-700 pb-4">FOLLOW US</h3>
            {social.map((s) => (
              <Button
                className="btn-neutral btn-primary btn-just-icon mr-1"
                style={{ color: "#fff" }}
                href={s.link}
                key={s.name}
              >
                <i className={`fa fa-${s.name}`} />
              </Button>
            ))}
          </Col>
        </Row>

        <Row>
          <Col xs={12} className={"text-center text-white pt-1 m-auto"}>
            <p className="text-400">©2022 EXIBEX FZ LLE. All Right Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/finnovex-middle-east/",
  },
  { name: "twitter", link: "https://twitter.com/Finnovexlive" },
  { name: "instagram", link: "https://www.instagram.com/finnovex/" },
  {
    name: "youtube",
    link: "https://www.youtube.com/channel/UCfxEoE4GycX1m7YeHdOZQXQ",
  },
  { name: "whatsapp", link: "https://wa.me/971585518437?text=Hi" },
];
