import React, { useEffect } from "react";
import anime from "animejs";

function AnimeBackground() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 200,
      delay: function (el, i) {
        return i * 15;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: "3rem",
        position: "absolute",
        bottom: -150,
        left: 0,
        width: "100%",
        zIndex: -100,
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1280 720"
        style={{
          fill: "rgba(255,255,255,0.1)",
          stroke: "#ee3388",
          strokeWidth: 3.5,
          strokeMiterlimit: 10,
        }}
      >
        <style type="text/css"></style>
        <g>
          <line class="st0" x1="178.2" y1="585.6" x2="0" y2="585.6" />
          <line class="st0" x1="1280" y1="585.6" x2="1071.1" y2="585.6" />
          <line class="st0" x1="957.7" y1="585.6" x2="892.7" y2="585.6" />
          <g>
            <path
              class="st0"
              d="M1014.4,456.4c-0.3,0-0.7,0-1-0.1l-2.6,7.2c1.2,0.2,2.4,0.4,3.7,0.4c1.3,0,2.5-0.1,3.7-0.4l-2.6-7.2
			C1015.1,456.4,1014.8,456.4,1014.4,456.4z"
            />
            <path
              class="st0"
              d="M1002.8,444.8c0-6.4,5.2-11.6,11.6-11.6s11.6,5.2,11.6,11.6c0,3.7-1.8,7-4.5,9.2l2.7,7.3
			c5.6-3.3,9.4-9.5,9.4-16.5c0-10.6-8.6-19.1-19.1-19.1c-10.6,0-19.1,8.6-19.1,19.1c0,7,3.8,13.1,9.4,16.5l2.7-7.3
			C1004.6,451.8,1002.8,448.5,1002.8,444.8z"
            />
            <path
              class="st0"
              d="M1064,570.5l-39.8-109.2l-2.7-7.3c-1.7,1.3-3.8,2.2-6.1,2.4l2.6,7.2l38.9,106.9H1064z"
            />
            <path
              class="st0"
              d="M1007.3,454l-2.7,7.3l-39.8,109.2h6.9l38.9-106.9l2.6-7.2C1011.1,456.1,1009,455.3,1007.3,454z"
            />
            <path
              class="st0"
              d="M1026,444.8c0-6.4-5.2-11.6-11.6-11.6s-11.6,5.2-11.6,11.6c0,3.7,1.8,7,4.5,9.2c1.7,1.3,3.8,2.2,6.1,2.4
			c0.3,0,0.7,0.1,1,0.1s0.7,0,1-0.1c2.3-0.2,4.4-1.1,6.1-2.4C1024.2,451.8,1026,448.5,1026,444.8z"
            />
            <path
              class="st0"
              d="M975.1,542.6c-38.7-15.6-66-53.5-66-97.8c0-58.2,47.2-105.4,105.4-105.4c58.2,0,105.4,47.2,105.4,105.4
			c0,44.3-27.3,82.2-66,97.8"
            />
            <path
              class="st0"
              d="M1047.6,544.8c-10.4,3.5-21.6,5.3-33.2,5.3c-11.6,0-22.8-1.9-33.2-5.3"
            />
            <path
              class="st0"
              d="M977.9,534.7c-35.5-14.4-60.5-49.2-60.5-89.9c0-53.6,43.4-97,97-97c53.6,0,97,43.4,97,97
			c0,40.7-25,75.5-60.5,89.9"
            />
            <path
              class="st0"
              d="M1044.8,537c-9.6,3.1-19.8,4.8-30.4,4.8c-10.6,0-20.8-1.7-30.4-4.8"
            />
            <path
              class="st0"
              d="M979.4,530.7c-33.8-13.8-57.7-47-57.7-85.9c0-51.2,41.5-92.7,92.7-92.7c51.2,0,92.7,41.5,92.7,92.7
			c0,38.8-23.8,72-57.7,85.9"
            />
            <path
              class="st0"
              d="M1043.3,532.9c-9.1,3-18.8,4.6-28.9,4.6c-10.1,0-19.8-1.6-28.9-4.6"
            />
            <path
              class="st0"
              d="M1022.8,331.4c0-3-3.7-5.4-8.4-5.4c-4.6,0-8.4,2.4-8.4,5.4c0,1.6,1.1,3,2.8,4v4.1h11.2v-4.1
			C1021.7,334.4,1022.8,332.9,1022.8,331.4z"
            />
            <path
              class="st0"
              d="M983.5,335.3c-1-2.8-5.4-3.8-9.7-2.2c-4.3,1.6-7,5.1-6,7.9c0.5,1.5,2,2.5,3.9,2.8l1.4,3.8l10.5-3.8l-1.4-3.8
			C983.5,338.5,984,336.8,983.5,335.3z"
            />
            <path
              class="st0"
              d="M947.9,352.5c-1.9-2.3-6.3-1.7-9.9,1.2s-4.8,7.2-2.9,9.5c1,1.2,2.7,1.6,4.7,1.3l2.6,3.1l8.6-7.2l-2.6-3.1
			C949,355.5,948.9,353.7,947.9,352.5z"
            />
            <path
              class="st0"
              d="M920.3,380.8c-2.6-1.5-6.5,0.5-8.8,4.5c-2.3,4-2.1,8.4,0.5,9.9c1.4,0.8,3.1,0.6,4.8-0.4l3.5,2l5.6-9.7l-3.5-2
			C922.4,383.3,921.7,381.6,920.3,380.8z"
            />
            <path
              class="st0"
              d="M904.1,416.9c-2.9-0.5-6,2.7-6.8,7.3c-0.8,4.5,0.9,8.6,3.9,9.2c1.6,0.3,3.1-0.5,4.4-2l4,0.7l1.9-11l-4-0.7
			C906.9,418.4,905.7,417.2,904.1,416.9z"
            />
            <path
              class="st0"
              d="M901.2,456.3c-2.9,0.5-4.7,4.6-3.9,9.2c0.8,4.5,3.8,7.8,6.8,7.3c1.6-0.3,2.8-1.6,3.5-3.4l4-0.7l-1.9-11
			l-4,0.7C904.4,456.8,902.8,456,901.2,456.3z"
            />
            <path
              class="st0"
              d="M912,494.3c-2.6,1.5-2.8,5.9-0.5,9.9c2.3,4,6.3,6,8.8,4.5c1.4-0.8,2.1-2.4,2.1-4.4l3.5-2l-5.6-9.7l-3.5,2
			C915.1,493.7,913.4,493.5,912,494.3z"
            />
            <path
              class="st0"
              d="M935.1,526.3c-1.9,2.3-0.6,6.5,2.9,9.5s7.9,3.5,9.9,1.2c1-1.2,1.1-3,0.5-4.8l2.6-3.1l-8.6-7.2l-2.6,3.1
			C937.8,524.7,936.1,525.1,935.1,526.3z"
            />
            <path
              class="st0"
              d="M973.1,541.9l-1.4,3.8c-1.9,0.4-3.4,1.3-3.9,2.8c-0.7,2,0.5,4.5,2.9,6.2"
            />
            <path
              class="st0"
              d="M976.7,557.1c3.2,0.3,6-0.7,6.8-2.9c0.5-1.5,0-3.2-1.2-4.7l1.4-3.8"
            />
            <path
              class="st0"
              d="M1006.1,558.2c0,3,3.7,5.4,8.4,5.4c4.6,0,8.4-2.4,8.4-5.4c0-1.6-1.1-3-2.8-4v-4.1h-11.2v4.1
			C1007.1,555.2,1006.1,556.7,1006.1,558.2z"
            />
            <path
              class="st0"
              d="M1045.2,545.7l1.4,3.8c-1.2,1.5-1.8,3.2-1.2,4.7c0.8,2.2,3.6,3.2,6.8,2.9"
            />
            <path
              class="st0"
              d="M1058,555c2.5-1.8,3.9-4.3,3.1-6.4c-0.5-1.5-2-2.5-3.9-2.8l-1.4-3.8"
            />
            <path
              class="st0"
              d="M1080.9,537.1c1.9,2.3,6.3,1.7,9.9-1.2c3.5-3,4.8-7.2,2.9-9.5c-1-1.2-2.7-1.6-4.7-1.3l-2.6-3.1l-8.6,7.2
			l2.6,3.1C1079.8,534.1,1079.9,535.9,1080.9,537.1z"
            />
            <path
              class="st0"
              d="M1108.5,508.8c2.6,1.5,6.5-0.5,8.8-4.5c2.3-4,2.1-8.4-0.5-9.9c-1.4-0.8-3.1-0.6-4.8,0.4l-3.5-2l-5.6,9.7
			l3.5,2C1106.4,506.3,1107.1,508,1108.5,508.8z"
            />
            <path
              class="st0"
              d="M1124.7,472.7c2.9,0.5,6-2.7,6.8-7.3c0.8-4.5-0.9-8.6-3.9-9.2c-1.6-0.3-3.1,0.5-4.4,2l-4-0.7l-1.9,11l4,0.7
			C1121.9,471.2,1123.1,472.5,1124.7,472.7z"
            />
            <path
              class="st0"
              d="M1127.6,433.3c2.9-0.5,4.7-4.6,3.9-9.2c-0.8-4.5-3.8-7.8-6.8-7.3c-1.6,0.3-2.8,1.6-3.5,3.4l-4,0.7l1.9,11
			l4-0.7C1124.4,432.8,1126,433.6,1127.6,433.3z"
            />
            <path
              class="st0"
              d="M1116.8,395.3c2.6-1.5,2.8-5.9,0.5-9.9c-2.3-4-6.3-6-8.8-4.5c-1.4,0.8-2.1,2.4-2.1,4.4l-3.5,2l5.6,9.7l3.5-2
			C1113.7,395.9,1115.5,396.1,1116.8,395.3z"
            />
            <path
              class="st0"
              d="M1093.7,363.3c1.9-2.3,0.6-6.5-2.9-9.5s-7.9-3.5-9.9-1.2c-1,1.2-1.1,3-0.5,4.8l-2.6,3.1l8.6,7.2l2.6-3.1
			C1091,364.9,1092.7,364.5,1093.7,363.3z"
            />
            <path
              class="st0"
              d="M1061.1,341.1c1-2.8-1.7-6.3-6-7.9c-4.3-1.6-8.7-0.6-9.7,2.2c-0.5,1.5,0,3.2,1.2,4.7l-1.4,3.8l10.5,3.8
			l1.4-3.8C1059,343.5,1060.5,342.5,1061.1,341.1z"
            />
            <line class="st0" x1="1014.4" y1="425.7" x2="1014.4" y2="352.1" />
            <line class="st0" x1="1009.5" y1="426.3" x2="990.4" y2="355.3" />
            <line class="st0" x1="1004.8" y1="428.2" x2="968.1" y2="364.5" />
            <line class="st0" x1="1000.9" y1="431.3" x2="948.9" y2="379.3" />
            <line class="st0" x1="997.8" y1="435.2" x2="934.1" y2="398.5" />
            <line class="st0" x1="995.9" y1="439.9" x2="924.9" y2="420.8" />
            <line class="st0" x1="995.3" y1="444.8" x2="921.7" y2="444.8" />
            <line class="st0" x1="995.9" y1="449.8" x2="924.9" y2="468.8" />
            <line class="st0" x1="997.8" y1="454.4" x2="934.1" y2="491.1" />
            <line class="st0" x1="1000.9" y1="458.3" x2="948.9" y2="510.3" />
            <line class="st0" x1="1004.8" y1="461.4" x2="968.1" y2="525.1" />
            <line class="st0" x1="1005.6" y1="477.8" x2="990.4" y2="534.3" />
            <line class="st0" x1="1014.4" y1="463.9" x2="1014.4" y2="537.5" />
            <line class="st0" x1="1023.3" y1="477.8" x2="1038.4" y2="534.3" />
            <line class="st0" x1="1024" y1="461.4" x2="1060.8" y2="525.1" />
            <line class="st0" x1="1027.9" y1="458.3" x2="1080" y2="510.3" />
            <line class="st0" x1="1031" y1="454.4" x2="1094.7" y2="491.1" />
            <line class="st0" x1="1032.9" y1="449.8" x2="1103.9" y2="468.8" />
            <line class="st0" x1="1033.5" y1="444.8" x2="1107.1" y2="444.8" />
            <line class="st0" x1="1032.9" y1="439.8" x2="1103.9" y2="420.8" />
            <line class="st0" x1="1031" y1="435.2" x2="1094.7" y2="398.5" />
            <line class="st0" x1="1027.9" y1="431.3" x2="1080" y2="379.3" />
            <line class="st0" x1="1024" y1="428.2" x2="1060.8" y2="364.5" />
            <line class="st0" x1="1019.4" y1="426.3" x2="1038.4" y2="355.3" />
            <circle class="st0" cx="1014.4" cy="444.8" r="4.3" />
            <polyline
              class="st0"
              points="1071.1,585.6 1071.1,570.5 957.7,570.5 957.7,585.6 		"
            />
          </g>
          <g>
            <rect x="844.9" y="541.9" class="st0" width="42.6" height="11.1" />
            <rect x="846.7" y="516.3" class="st0" width="38.9" height="25.5" />
            <rect x="844.9" y="510.4" class="st0" width="42.6" height="5.9" />
            <rect x="846.7" y="493" class="st0" width="38.9" height="17.4" />
            <rect x="844.9" y="487.1" class="st0" width="42.6" height="5.9" />
            <rect x="846.7" y="469.7" class="st0" width="38.9" height="17.4" />
            <rect x="844.9" y="463.8" class="st0" width="42.6" height="5.9" />
            <rect x="846.7" y="444.5" class="st0" width="38.9" height="19.2" />
            <rect x="844.9" y="438.6" class="st0" width="42.6" height="5.9" />
            <rect x="846.7" y="423.4" class="st0" width="10.4" height="15.2" />
            <rect x="875.2" y="423.4" class="st0" width="10.4" height="15.2" />
            <polygon
              class="st0"
              points="851.9,406.9 846.7,423.4 857.1,423.4 		"
            />
            <polygon
              class="st0"
              points="880.4,406.9 875.2,423.4 885.6,423.4 		"
            />
            <line class="st0" x1="857.1" y1="425.5" x2="875.2" y2="425.5" />
            <polyline class="st0" points="876.4,419.8 866.2,394.6 856,419.8 		" />
            <line class="st0" x1="851.9" y1="406.9" x2="851.9" y2="400.3" />
            <line class="st0" x1="849.7" y1="402.5" x2="854.1" y2="402.5" />
            <line class="st0" x1="880.4" y1="406.9" x2="880.4" y2="400.3" />
            <line class="st0" x1="866.2" y1="394.6" x2="866.2" y2="388" />
            <line class="st0" x1="878.2" y1="402.5" x2="882.6" y2="402.5" />
            <g>
              <polygon
                class="st0"
                points="863.1,432.2 861.9,427.5 860.7,432.2 860.7,436.8 863.1,436.8 			"
              />
              <polygon
                class="st0"
                points="871.6,432.2 870.4,427.5 869.2,432.2 869.2,436.8 871.6,436.8 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="863.1,451.7 861.9,447 860.7,451.7 860.7,461.3 863.1,461.3 			"
              />
              <polygon
                class="st0"
                points="871.6,451.7 870.4,447 869.2,451.7 869.2,461.3 871.6,461.3 			"
              />
              <polygon
                class="st0"
                points="878.8,451.7 877.6,447 876.4,451.7 876.4,461.3 878.8,461.3 			"
              />
              <polygon
                class="st0"
                points="853.6,451.7 854.8,447 856,451.7 856,461.3 853.6,461.3 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="860.5,476.3 858.2,472.4 856,476.3 856,484.4 860.5,484.4 			"
              />
              <polygon
                class="st0"
                points="868.4,476.3 866.2,472.4 863.9,476.3 863.9,484.4 868.4,484.4 			"
              />
              <polygon
                class="st0"
                points="876.4,476.3 874.1,472.4 871.9,476.3 871.9,484.4 876.4,484.4 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="860.5,499.6 858.2,495.7 856,499.6 856,507.7 860.5,507.7 			"
              />
              <polygon
                class="st0"
                points="868.4,499.6 866.2,495.7 863.9,499.6 863.9,507.7 868.4,507.7 			"
              />
              <polygon
                class="st0"
                points="876.4,499.6 874.1,495.7 871.9,499.6 871.9,507.7 876.4,507.7 			"
              />
            </g>
            <rect x="854.2" y="532.1" class="st0" width="24" height="2.3" />
            <rect x="854.2" y="538.1" class="st0" width="24" height="2.3" />

            <rect
              x="871.9"
              y="522"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1747.4058 1049.6816)"
              class="st0"
              width="3.6"
              height="5.7"
            />

            <rect
              x="862.9"
              y="523.8"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1732.3461 1055.7155)"
              class="st0"
              width="6.5"
              height="8.2"
            />

            <rect
              x="862.9"
              y="534.4"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1732.3461 1072.2926)"
              class="st0"
              width="6.5"
              height="3.5"
            />
            <rect x="856.8" y="522" class="st0" width="3.6" height="5.7" />
            <polyline
              class="st0"
              points="892.7,585.6 892.7,553 839.6,553 839.6,585.6 		"
            />
            <rect x="701.4" y="541.9" class="st0" width="42.6" height="11.1" />
            <rect x="703.2" y="516.3" class="st0" width="38.9" height="25.5" />
            <rect x="701.4" y="510.4" class="st0" width="42.6" height="5.9" />
            <rect x="703.2" y="493" class="st0" width="38.9" height="17.4" />
            <rect x="701.4" y="487.1" class="st0" width="42.6" height="5.9" />
            <rect x="703.2" y="469.7" class="st0" width="38.9" height="17.4" />
            <rect x="701.4" y="463.8" class="st0" width="42.6" height="5.9" />
            <rect x="703.2" y="444.5" class="st0" width="38.9" height="19.2" />
            <rect x="701.4" y="438.6" class="st0" width="42.6" height="5.9" />
            <rect x="703.2" y="423.4" class="st0" width="10.4" height="15.2" />
            <rect x="731.7" y="423.4" class="st0" width="10.4" height="15.2" />
            <polygon
              class="st0"
              points="708.4,406.9 703.2,423.4 713.6,423.4 		"
            />
            <polygon
              class="st0"
              points="736.9,406.9 731.7,423.4 742.1,423.4 		"
            />
            <line class="st0" x1="713.6" y1="425.5" x2="731.7" y2="425.5" />
            <polyline
              class="st0"
              points="732.8,419.8 722.7,394.6 712.5,419.8 		"
            />
            <line class="st0" x1="708.4" y1="406.9" x2="708.4" y2="400.3" />
            <line class="st0" x1="706.2" y1="402.5" x2="710.6" y2="402.5" />
            <line class="st0" x1="736.9" y1="406.9" x2="736.9" y2="400.3" />
            <line class="st0" x1="722.7" y1="394.6" x2="722.7" y2="388" />
            <line class="st0" x1="734.7" y1="402.5" x2="739.1" y2="402.5" />
            <g>
              <polygon
                class="st0"
                points="719.6,432.2 718.4,427.5 717.2,432.2 717.2,436.8 719.6,436.8 			"
              />
              <polygon
                class="st0"
                points="728.1,432.2 726.9,427.5 725.7,432.2 725.7,436.8 728.1,436.8 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="719.6,451.7 718.4,447 717.2,451.7 717.2,461.3 719.6,461.3 			"
              />
              <polygon
                class="st0"
                points="728.1,451.7 726.9,447 725.7,451.7 725.7,461.3 728.1,461.3 			"
              />
              <polygon
                class="st0"
                points="735.3,451.7 734.1,447 732.8,451.7 732.8,461.3 735.3,461.3 			"
              />
              <polygon
                class="st0"
                points="710,451.7 711.3,447 712.5,451.7 712.5,461.3 710,461.3 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="716.9,476.3 714.7,472.4 712.5,476.3 712.5,484.4 716.9,484.4 			"
              />
              <polygon
                class="st0"
                points="724.9,476.3 722.7,472.4 720.4,476.3 720.4,484.4 724.9,484.4 			"
              />
              <polygon
                class="st0"
                points="732.8,476.3 730.6,472.4 728.4,476.3 728.4,484.4 732.8,484.4 			"
              />
            </g>
            <g>
              <polygon
                class="st0"
                points="716.9,499.6 714.7,495.7 712.5,499.6 712.5,507.7 716.9,507.7 			"
              />
              <polygon
                class="st0"
                points="724.9,499.6 722.7,495.7 720.4,499.6 720.4,507.7 724.9,507.7 			"
              />
              <polygon
                class="st0"
                points="732.8,499.6 730.6,495.7 728.4,499.6 728.4,507.7 732.8,507.7 			"
              />
            </g>
            <rect x="710.6" y="532.1" class="st0" width="24" height="2.3" />
            <rect x="710.6" y="538.1" class="st0" width="24" height="2.3" />

            <rect
              x="728.4"
              y="522"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1460.3687 1049.6816)"
              class="st0"
              width="3.6"
              height="5.7"
            />

            <rect
              x="719.4"
              y="523.8"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1445.3088 1055.7155)"
              class="st0"
              width="6.5"
              height="8.2"
            />

            <rect
              x="719.4"
              y="534.4"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1445.3088 1072.2926)"
              class="st0"
              width="6.5"
              height="3.5"
            />
            <rect x="713.3" y="522" class="st0" width="3.6" height="5.7" />
            <polyline
              class="st0"
              points="749.2,585.6 749.2,553 696.1,553 696.1,585.6 		"
            />
            <line class="st0" x1="743.9" y1="466.7" x2="844.9" y2="466.7" />
            <line class="st0" x1="742.1" y1="458.3" x2="846.7" y2="458.3" />
            <line class="st0" x1="742.1" y1="448.5" x2="846.7" y2="448.5" />
            <line class="st0" x1="742.1" y1="450.8" x2="846.7" y2="450.8" />
            <rect x="785.6" y="445.7" class="st0" width="17.6" height="2.7" />
            <path
              class="st0"
              d="M790.8,445.7c0-2,1.6-3.6,3.6-3.6s3.6,1.6,3.6,3.6"
            />
            <g>
              <rect x="747.1" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="753.6" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="760.2" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="766.7" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="773.2" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="779.7" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="786.3" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="792.8" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="799.3" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="805.9" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="812.4" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="818.9" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="825.4" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="832" y="450.8" class="st0" width="3.2" height="7.6" />
              <rect x="838.5" y="450.8" class="st0" width="3.2" height="7.6" />
            </g>
            <line class="st0" x1="749.2" y1="553" x2="839.6" y2="553" />
            <polyline class="st0" points="749.2,571.9 794.4,558 839.3,571.9 		" />
            <line class="st0" x1="749.2" y1="558" x2="839.6" y2="558" />
            <line class="st0" x1="749.3" y1="558" x2="749.3" y2="565.5" />
            <line class="st0" x1="755.7" y1="558" x2="755.7" y2="569.8" />
            <line class="st0" x1="762.2" y1="558" x2="762.2" y2="568.1" />
            <line class="st0" x1="768.7" y1="558" x2="768.7" y2="565.5" />
            <line class="st0" x1="775.1" y1="558" x2="775.1" y2="563.5" />
            <line class="st0" x1="781.6" y1="558" x2="781.6" y2="561.8" />
            <line class="st0" x1="839.6" y1="558" x2="839.6" y2="565.5" />
            <line class="st0" x1="833.1" y1="558" x2="833.1" y2="569.8" />
            <line class="st0" x1="826.6" y1="558" x2="826.6" y2="568.1" />
            <line class="st0" x1="820.2" y1="558" x2="820.2" y2="565.5" />
            <line class="st0" x1="813.7" y1="558" x2="813.7" y2="563.5" />
            <line class="st0" x1="807.3" y1="558" x2="807.3" y2="561.8" />
          </g>
          <g>
            <rect x="644.9" y="278.7" class="st0" width="43" height="12.5" />
            <rect x="641.8" y="307.6" class="st0" width="49.1" height="19.4" />
            <rect x="638.5" y="327" class="st0" width="55.8" height="15.3" />
            <rect x="641.8" y="342.4" class="st0" width="49.1" height="16.4" />
            <rect x="645.9" y="358.7" class="st0" width="40.9" height="13.6" />
            <rect x="656.9" y="291.2" class="st0" width="18.9" height="16.4" />
            <path
              class="st0"
              d="M668.9,278.7h-5.1v-50.9c0-1.4,1.1-2.6,2.6-2.6l0,0c1.4,0,2.6,1.1,2.6,2.6V278.7z"
            />
            <rect x="648.2" y="372.3" class="st0" width="36.3" height="14.3" />
            <rect x="648.2" y="391.7" class="st0" width="36.3" height="14.3" />
            <rect x="648.2" y="411.2" class="st0" width="36.3" height="14.3" />
            <rect x="648.2" y="430.6" class="st0" width="36.3" height="14.3" />
            <rect x="648.2" y="450" class="st0" width="36.3" height="14.3" />
            <polyline
              class="st0"
              points="684.5,585.6 684.5,469.5 648.2,469.5 648.2,585.6 		"
            />
            <rect x="652.3" y="386.6" class="st0" width="28.1" height="5.1" />
            <rect x="652.3" y="406" class="st0" width="28.1" height="5.1" />
            <rect x="652.3" y="425.5" class="st0" width="28.1" height="5.1" />
            <rect x="652.3" y="444.9" class="st0" width="28.1" height="5.1" />
            <rect x="652.3" y="464.4" class="st0" width="28.1" height="5.1" />
            <line class="st0" x1="648.2" y1="379.5" x2="684.5" y2="379.5" />
            <line class="st0" x1="648.2" y1="383.1" x2="684.5" y2="383.1" />
            <line class="st0" x1="648.2" y1="398.9" x2="684.5" y2="398.9" />
            <line class="st0" x1="648.2" y1="402.5" x2="684.5" y2="402.5" />
            <line class="st0" x1="648.2" y1="418.3" x2="684.5" y2="418.3" />
            <line class="st0" x1="648.2" y1="422" x2="684.5" y2="422" />
            <line class="st0" x1="648.2" y1="437.8" x2="684.5" y2="437.8" />
            <line class="st0" x1="648.2" y1="441.4" x2="684.5" y2="441.4" />
            <line class="st0" x1="648.2" y1="457.2" x2="684.5" y2="457.2" />
            <line class="st0" x1="648.2" y1="460.8" x2="684.5" y2="460.8" />
            <line class="st0" x1="666.3" y1="278.7" x2="666.3" y2="291.2" />
            <line class="st0" x1="661.1" y1="278.7" x2="661.1" y2="291.2" />
            <line class="st0" x1="655.8" y1="278.7" x2="655.8" y2="291.2" />
            <line class="st0" x1="650.5" y1="278.7" x2="650.5" y2="291.2" />
            <line class="st0" x1="671.6" y1="278.7" x2="671.6" y2="291.2" />
            <line class="st0" x1="676.9" y1="278.7" x2="676.9" y2="291.2" />
            <line class="st0" x1="682.1" y1="278.7" x2="682.1" y2="291.2" />
            <line class="st0" x1="666.3" y1="358.7" x2="666.3" y2="372.3" />
            <line class="st0" x1="661.1" y1="358.7" x2="661.1" y2="372.3" />
            <line class="st0" x1="655.8" y1="358.7" x2="655.8" y2="372.3" />
            <line class="st0" x1="650.5" y1="358.7" x2="650.5" y2="372.3" />
            <line class="st0" x1="671.6" y1="358.7" x2="671.6" y2="372.3" />
            <line class="st0" x1="676.9" y1="358.7" x2="676.9" y2="372.3" />
            <line class="st0" x1="682.1" y1="358.7" x2="682.1" y2="372.3" />
            <line class="st0" x1="666.3" y1="469.5" x2="666.3" y2="483" />
            <line class="st0" x1="661.1" y1="469.5" x2="661.1" y2="483" />
            <line class="st0" x1="655.8" y1="469.5" x2="655.8" y2="483" />
            <line class="st0" x1="650.5" y1="469.5" x2="650.5" y2="483" />
            <line class="st0" x1="671.6" y1="469.5" x2="671.6" y2="483" />
            <line class="st0" x1="676.9" y1="469.5" x2="676.9" y2="483" />
            <line class="st0" x1="682.1" y1="469.5" x2="682.1" y2="483" />
            <line class="st0" x1="666.3" y1="307.6" x2="666.3" y2="327" />
            <line class="st0" x1="661.1" y1="307.6" x2="661.1" y2="327" />
            <line class="st0" x1="655.8" y1="307.6" x2="655.8" y2="327" />
            <line class="st0" x1="650.5" y1="307.6" x2="650.5" y2="327" />
            <line class="st0" x1="645.3" y1="307.6" x2="645.3" y2="327" />
            <line class="st0" x1="687.4" y1="307.6" x2="687.4" y2="327" />
            <line class="st0" x1="671.6" y1="307.6" x2="671.6" y2="327" />
            <line class="st0" x1="676.9" y1="307.6" x2="676.9" y2="327" />
            <line class="st0" x1="682.1" y1="307.6" x2="682.1" y2="327" />
            <line class="st0" x1="666.3" y1="342.4" x2="666.3" y2="358.7" />
            <line class="st0" x1="661.1" y1="342.4" x2="661.1" y2="358.7" />
            <line class="st0" x1="655.8" y1="342.4" x2="655.8" y2="358.7" />
            <line class="st0" x1="650.5" y1="342.4" x2="650.5" y2="358.7" />
            <line class="st0" x1="645.3" y1="342.4" x2="645.3" y2="358.7" />
            <line class="st0" x1="687.4" y1="342.4" x2="687.4" y2="358.7" />
            <line class="st0" x1="671.6" y1="342.4" x2="671.6" y2="358.7" />
            <line class="st0" x1="676.9" y1="342.4" x2="676.9" y2="358.7" />
            <line class="st0" x1="682.1" y1="342.4" x2="682.1" y2="358.7" />
            <line class="st0" x1="666.3" y1="327" x2="666.3" y2="342.4" />
            <line class="st0" x1="660" y1="327" x2="660" y2="342.4" />
            <line class="st0" x1="653.6" y1="327" x2="653.6" y2="342.4" />
            <line class="st0" x1="647.3" y1="327" x2="647.3" y2="342.4" />
            <line class="st0" x1="640.9" y1="327" x2="640.9" y2="342.4" />
            <line class="st0" x1="691.7" y1="327" x2="691.7" y2="342.4" />
            <line class="st0" x1="672.7" y1="327" x2="672.7" y2="342.4" />
            <line class="st0" x1="679" y1="327" x2="679" y2="342.4" />
            <line class="st0" x1="685.4" y1="327" x2="685.4" y2="342.4" />
            <line class="st0" x1="648.2" y1="483" x2="684.5" y2="483" />
            <line class="st0" x1="648.2" y1="488.1" x2="684.5" y2="488.1" />
            <line class="st0" x1="666.3" y1="488.1" x2="666.3" y2="501.7" />
            <line class="st0" x1="661.1" y1="488.1" x2="661.1" y2="501.7" />
            <line class="st0" x1="655.8" y1="488.1" x2="655.8" y2="501.7" />
            <line class="st0" x1="650.5" y1="488.1" x2="650.5" y2="501.7" />
            <line class="st0" x1="671.6" y1="488.1" x2="671.6" y2="501.7" />
            <line class="st0" x1="676.9" y1="488.1" x2="676.9" y2="501.7" />
            <line class="st0" x1="682.1" y1="488.1" x2="682.1" y2="501.7" />
            <line class="st0" x1="648.2" y1="501.7" x2="684.5" y2="501.7" />
            <line class="st0" x1="648.2" y1="506.7" x2="684.5" y2="506.7" />
            <line class="st0" x1="666.3" y1="506.7" x2="666.3" y2="520.3" />
            <line class="st0" x1="661.1" y1="506.7" x2="661.1" y2="520.3" />
            <line class="st0" x1="655.8" y1="506.7" x2="655.8" y2="520.3" />
            <line class="st0" x1="650.5" y1="506.7" x2="650.5" y2="520.3" />
            <line class="st0" x1="671.6" y1="506.7" x2="671.6" y2="520.3" />
            <line class="st0" x1="676.9" y1="506.7" x2="676.9" y2="520.3" />
            <line class="st0" x1="682.1" y1="506.7" x2="682.1" y2="520.3" />
            <line class="st0" x1="648.2" y1="520.3" x2="684.5" y2="520.3" />
            <line class="st0" x1="648.2" y1="525.4" x2="684.5" y2="525.4" />
            <line class="st0" x1="666.3" y1="525.4" x2="666.3" y2="538.9" />
            <line class="st0" x1="661.1" y1="525.4" x2="661.1" y2="538.9" />
            <line class="st0" x1="655.8" y1="525.4" x2="655.8" y2="538.9" />
            <line class="st0" x1="650.5" y1="525.4" x2="650.5" y2="538.9" />
            <line class="st0" x1="671.6" y1="525.4" x2="671.6" y2="538.9" />
            <line class="st0" x1="676.9" y1="525.4" x2="676.9" y2="538.9" />
            <line class="st0" x1="682.1" y1="525.4" x2="682.1" y2="538.9" />
            <line class="st0" x1="648.2" y1="538.9" x2="684.5" y2="538.9" />
            <line class="st0" x1="648.2" y1="544" x2="684.5" y2="544" />
            <line class="st0" x1="666.3" y1="544" x2="666.3" y2="557.5" />
            <line class="st0" x1="661.1" y1="544" x2="661.1" y2="557.5" />
            <line class="st0" x1="655.8" y1="544" x2="655.8" y2="557.5" />
            <line class="st0" x1="650.5" y1="544" x2="650.5" y2="557.5" />
            <line class="st0" x1="671.6" y1="544" x2="671.6" y2="557.5" />
            <line class="st0" x1="676.9" y1="544" x2="676.9" y2="557.5" />
            <line class="st0" x1="682.1" y1="544" x2="682.1" y2="557.5" />
            <line class="st0" x1="648.2" y1="557.5" x2="684.5" y2="557.5" />
            <line class="st0" x1="648.2" y1="562.6" x2="684.5" y2="562.6" />
            <line class="st0" x1="666.3" y1="562.6" x2="666.3" y2="576.2" />
            <line class="st0" x1="661.1" y1="562.6" x2="661.1" y2="576.2" />
            <line class="st0" x1="655.8" y1="562.6" x2="655.8" y2="576.2" />
            <line class="st0" x1="650.5" y1="562.6" x2="650.5" y2="576.2" />
            <line class="st0" x1="671.6" y1="562.6" x2="671.6" y2="576.2" />
            <line class="st0" x1="676.9" y1="562.6" x2="676.9" y2="576.2" />
            <line class="st0" x1="682.1" y1="562.6" x2="682.1" y2="576.2" />
            <line class="st0" x1="648.2" y1="576.2" x2="684.5" y2="576.2" />
            <line class="st0" x1="648.2" y1="581.3" x2="684.5" y2="581.3" />
          </g>
          <line class="st0" x1="696.1" y1="585.6" x2="684.5" y2="585.6" />
          <g>
            <polyline
              class="st0"
              points="560.3,585.6 560.3,509.2 624,509.2 624,585.6 		"
            />
            <rect x="565.8" y="415.7" class="st0" width="52.6" height="93.5" />
            <rect x="565.8" y="415.7" class="st0" width="9.5" height="93.5" />
            <rect x="608.9" y="415.7" class="st0" width="9.5" height="93.5" />
            <rect x="575.3" y="446.3" class="st0" width="33.6" height="7.7" />
            <rect x="561.8" y="396.2" class="st0" width="60.7" height="19.5" />
            <rect x="575.3" y="478.3" class="st0" width="33.6" height="7.7" />
            <rect x="575.3" y="540.6" class="st0" width="33.6" height="7.7" />
            <rect x="561.8" y="356.5" class="st0" width="10" height="39.7" />
            <rect x="612.5" y="356.5" class="st0" width="10" height="39.7" />
            <rect x="566.8" y="342.8" class="st0" width="50.7" height="13.7" />
            <polyline
              class="st0"
              points="561.8,356.5 566.8,342.8 617.5,342.8 622.5,356.5 		"
            />

            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -92.7036 528.938)"
              class="st0"
              cx="592.1"
              cy="376.4"
              rx="17.9"
              ry="17.9"
            />
            <line class="st0" x1="592.1" y1="376.4" x2="592.1" y2="361.2" />
            <line class="st0" x1="592.1" y1="376.4" x2="599.1" y2="383.4" />
            <polyline
              class="st0"
              points="617.5,342.8 606.7,320 577.5,320 566.8,342.8 		"
            />
            <rect x="577.5" y="302.4" class="st0" width="29.3" height="17.6" />
            <polygon
              class="st0"
              points="577.5,302.4 592.1,251.3 606.8,302.4 		"
            />
            <rect x="575.3" y="509.2" class="st0" width="33.6" height="57.3" />
            <polyline
              class="st0"
              points="371,585.6 371,509.2 560.3,509.2 560.3,585.6 		"
            />
            <rect x="313.5" y="540.6" class="st0" width="246.8" height="7.7" />
            <rect x="313.5" y="509.2" class="st0" width="57.5" height="7.7" />
            <polyline
              class="st0"
              points="313.8,585.6 313.8,473.8 371,473.8 371,585.6 		"
            />
            <rect x="310.3" y="466.9" class="st0" width="64.1" height="6.9" />
            <rect x="313.5" y="438.8" class="st0" width="11.1" height="28.1" />
            <rect x="359.8" y="438.8" class="st0" width="11.1" height="28.1" />
            <rect x="338.1" y="445.5" class="st0" width="8.5" height="21.4" />
            <line class="st0" x1="371" y1="486" x2="565.8" y2="486" />
            <g>
              <rect x="382.2" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="402.1" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="421.9" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="441.8" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="461.7" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="481.5" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="501.4" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="521.2" y="493.9" class="st0" width="7.9" height="15.3" />
              <rect x="541.1" y="493.9" class="st0" width="7.9" height="15.3" />
            </g>
            <path
              class="st0"
              d="M327,433.5c0,2-0.8,3.9-2,5.3l-11.8,0c-1.3-1.4-2-3.3-2-5.3c0-4.4,3.6-7.9,7.9-7.9
			C323.5,425.6,327,429.1,327,433.5z"
            />
            <path
              class="st0"
              d="M373.3,433.5c0,2-0.8,3.9-2,5.3l-11.8,0c-1.3-1.4-2-3.3-2-5.3c0-4.4,3.6-7.9,7.9-7.9
			C369.8,425.6,373.3,429.1,373.3,433.5z"
            />
            <line class="st0" x1="319.1" y1="425.6" x2="319.1" y2="407.8" />
            <line class="st0" x1="365.4" y1="425.6" x2="365.4" y2="407.8" />
            <g>
              <rect x="382.2" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="382.2" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="382.2" y1="528.8" x2="390.2" y2="528.8" />
              <line class="st0" x1="386.2" y1="516.9" x2="386.2" y2="540.6" />
              <g>
                <rect x="382.2" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="382.2" y1="566.8" x2="390.2" y2="566.8" />
                <line class="st0" x1="386.2" y1="555" x2="386.2" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="402.1" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="402.1" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="402.1" y1="528.8" x2="410" y2="528.8" />
              <line class="st0" x1="406.1" y1="516.9" x2="406.1" y2="540.6" />
              <g>
                <rect x="402.1" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="402.1" y1="566.8" x2="410" y2="566.8" />
                <line class="st0" x1="406.1" y1="555" x2="406.1" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="328.5" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="328.5" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="328.5" y1="528.8" x2="336.4" y2="528.8" />
              <line class="st0" x1="332.4" y1="516.9" x2="332.4" y2="540.6" />
              <g>
                <rect x="328.5" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="328.5" y1="566.8" x2="336.4" y2="566.8" />
                <line class="st0" x1="332.4" y1="555" x2="332.4" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="348.3" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="348.3" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="348.3" y1="528.8" x2="356.3" y2="528.8" />
              <line class="st0" x1="352.3" y1="516.9" x2="352.3" y2="540.6" />
              <g>
                <rect x="348.3" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="348.3" y1="566.8" x2="356.3" y2="566.8" />
                <line class="st0" x1="352.3" y1="555" x2="352.3" y2="578.7" />
              </g>
            </g>
            <g>
              <path
                class="st0"
                d="M336.4,509.1h-7.9v-28.4c0-1.1,0.9-2,2-2h4c1.1,0,2,0.9,2,2V509.1z"
              />
              <g>
                <rect
                  x="328.5"
                  y="485.4"
                  class="st0"
                  width="7.9"
                  height="23.7"
                />
                <line class="st0" x1="328.5" y1="497.2" x2="336.4" y2="497.2" />
                <line class="st0" x1="332.4" y1="485.4" x2="332.4" y2="509.1" />
              </g>
            </g>
            <g>
              <path
                class="st0"
                d="M356.3,509.1h-7.9v-26.4c0-2.2,1.8-4,4-4l0,0c2.2,0,4,1.8,4,4V509.1z"
              />
              <g>
                <rect
                  x="348.3"
                  y="485.4"
                  class="st0"
                  width="7.9"
                  height="23.7"
                />
                <line class="st0" x1="348.3" y1="497.2" x2="356.3" y2="497.2" />
                <line class="st0" x1="352.3" y1="485.4" x2="352.3" y2="509.1" />
              </g>
            </g>
            <g>
              <rect x="421.9" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="421.9" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="421.9" y1="528.8" x2="429.9" y2="528.8" />
              <line class="st0" x1="425.9" y1="516.9" x2="425.9" y2="540.6" />
              <g>
                <rect x="421.9" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="421.9" y1="566.8" x2="429.9" y2="566.8" />
                <line class="st0" x1="425.9" y1="555" x2="425.9" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="441.8" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="441.8" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="441.8" y1="528.8" x2="449.7" y2="528.8" />
              <line class="st0" x1="445.8" y1="516.9" x2="445.8" y2="540.6" />
              <g>
                <rect x="441.8" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="441.8" y1="566.8" x2="449.7" y2="566.8" />
                <line class="st0" x1="445.8" y1="555" x2="445.8" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="461.7" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="461.7" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="461.7" y1="528.8" x2="469.6" y2="528.8" />
              <line class="st0" x1="465.6" y1="516.9" x2="465.6" y2="540.6" />
              <g>
                <rect x="461.7" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="461.7" y1="566.8" x2="469.6" y2="566.8" />
                <line class="st0" x1="465.6" y1="555" x2="465.6" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="481.5" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="481.5" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="481.5" y1="528.8" x2="489.5" y2="528.8" />
              <line class="st0" x1="485.5" y1="516.9" x2="485.5" y2="540.6" />
              <g>
                <rect x="481.5" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="481.5" y1="566.8" x2="489.5" y2="566.8" />
                <line class="st0" x1="485.5" y1="555" x2="485.5" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="501.4" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="501.4" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="501.4" y1="528.8" x2="509.3" y2="528.8" />
              <line class="st0" x1="505.3" y1="516.9" x2="505.3" y2="540.6" />
              <g>
                <rect x="501.4" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="501.4" y1="566.8" x2="509.3" y2="566.8" />
                <line class="st0" x1="505.3" y1="555" x2="505.3" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="521.2" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="521.2" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="521.2" y1="528.8" x2="529.2" y2="528.8" />
              <line class="st0" x1="525.2" y1="516.9" x2="525.2" y2="540.6" />
              <g>
                <rect x="521.2" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="521.2" y1="566.8" x2="529.2" y2="566.8" />
                <line class="st0" x1="525.2" y1="555" x2="525.2" y2="578.7" />
              </g>
            </g>
            <g>
              <rect x="541.1" y="516.9" class="st0" width="7.9" height="23.7" />
              <rect x="541.1" y="548.3" class="st0" width="7.9" height="37" />
              <line class="st0" x1="541.1" y1="528.8" x2="549" y2="528.8" />
              <line class="st0" x1="545.1" y1="516.9" x2="545.1" y2="540.6" />
              <g>
                <rect x="541.1" y="555" class="st0" width="7.9" height="23.7" />
                <line class="st0" x1="541.1" y1="566.8" x2="549" y2="566.8" />
                <line class="st0" x1="545.1" y1="555" x2="545.1" y2="578.7" />
              </g>
            </g>
            <line class="st0" x1="324.6" y1="448.4" x2="338.1" y2="448.4" />
            <line class="st0" x1="346.6" y1="448.4" x2="359.8" y2="448.4" />
          </g>
          <g>
            <path
              class="st0"
              d="M294.3,585.7c0-92.9-26-253.6-58-253.6s-58,160.7-58,253.6"
            />
            <path
              class="st0"
              d="M203.5,389.1c0,0,3.3-3.1,32.8-3.1s32.8,3.1,32.8,3.1"
            />
            <path
              class="st0"
              d="M205.1,383.5c2.9-0.9,10.8-2.4,31.2-2.4c20.4,0,28.3,1.5,31.2,2.4"
            />
            <path class="st0" d="M225.2,386.2c13,10.1,29.4,19.9,50,28.2" />
            <path class="st0" d="M200.2,401.9c15.7,19.4,41.3,40.4,83.1,55.8" />
            <path class="st0" d="M193.5,433c15.3,22.5,43.5,48.9,95.5,66.8" />
            <path class="st0" d="M188.2,464.5c14,24.5,43.2,56.4,104.5,76.6" />
            <path class="st0" d="M183.9,496.5c12,25.4,40.9,62.8,110.3,85.2" />
            <path class="st0" d="M178.2,585.7" />
            <path class="st0" d="M180.7,529.1c5.8,14.9,17.9,36.4,43.2,56.6" />
            <path class="st0" d="M178.7,563.3c1.9,6.1,5,13.8,10,22.4" />
            <path class="st0" d="M247.3,386.2c-13,10.1-29.4,19.9-50,28.2" />
            <path class="st0" d="M272.3,401.9c-15.7,19.4-41.3,40.4-83.1,55.8" />
            <path class="st0" d="M279,433c-15.3,22.5-43.5,48.9-95.5,66.8" />
            <path class="st0" d="M284.3,464.5c-14,24.5-43.2,56.4-104.5,76.6" />
            <path class="st0" d="M288.6,496.5c-12,25.4-40.9,62.8-110.3,85.2" />
            <path class="st0" d="M291.8,529.1c-5.8,14.9-17.9,36.4-43.2,56.6" />
            <path class="st0" d="M293.8,563.3c-1.9,6.1-5,13.8-10,22.4" />
          </g>

          <line class="st0" x1="294.3" y1="585.7" x2="313.8" y2="585.7" />
          <line class="st0" x1="624" y1="585.6" x2="648.2" y2="585.6" />
        </g>
      </svg>
    </div>
  );
}

export default AnimeBackground;
