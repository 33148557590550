import React from "react";
import { Container, Button, Row, Col } from "reactstrap";

function WhoShouldAttend() {
  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
      }}
      className="section p-5"
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className="text-700 mb-2 border-bottom border-info">
              Who Should Attend
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={12}>
            <h5 className="text-700 my-3">
              Meet and Network with CEOs, CDO’s, COO’s, CIOs, CISOs, CTOs, CRO’s
              Senior Vice Presidents, Vice Presidents, Directors, and Heads of
              departments from Banking and FI industry involved in:
            </h5>
          </Col>
          {wsaContent.map((d, index) => (
            <Col lg={4} key={index}>
              <ul
                style={{
                  listStyleImage: `url(${require("assets/images/icons/next.png")}) `,
                }}
              >
                {d.column.map((c, i) => (
                  <li key={i}>
                    <h5 className="text-400 m-2">{c.text}</h5>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
        <Row className="justify-content-center">
          <Button className="p-2" color="primary" size="md" href="/register">
            <h5 className="m-0 text-400 text-white">Register Now</h5>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhoShouldAttend;

const wsaContent = [
  {
    column: [
      { text: "Digital Transformation" },
      { text: "Corporate banking" },
      { text: "Digital Channels " },
      { text: "SME Banking" },
      { text: "Strategy & Operations" },
      { text: "E-channels & Payments" },
    ],
  },
  {
    column: [
      { text: "Product Development" },
      { text: "Customer Experience" },
      { text: "Information Technology" },
      { text: "Business Innovation" },
      { text: "Retail Banking" },
      { text: "R&D Innovation" },
    ],
  },
  {
    column: [
      { text: "Risk & Compliance Management" },
      { text: "Marketing & Communications" },
      { text: "Cyber & Cloud Security" },
      { text: "Fintech & Insurtech" },
      { text: "Big Data Analytics, AI, Open Banking (API), Blockchain." },
    ],
  },
];
