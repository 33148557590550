import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function Awards() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      {!size && (
        <img
          src={require("assets/images/awardbanner2mobile.png")}
          width="100%"
          alt="img"
        />
      )}
      <div
        style={{
          background: size
            ? "url(" + require(`assets/images/awardsfull.png`) + ")"
            : "#000",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={`section ${!size && "py-2"}`}
        data-parallax={true}
        ref={pageHeader}
      >
        {/* <div className="overlay-secondary"></div> */}
        <Container>
          <Row>
            <Col lg={6} className="align-self-center text-center">
              {/* {!size && (
								<img
									src={require(`assets/images/awardslogo.png`)}
									width="100%"
									style={{ maxWidth: '350px' }}
									alt="awards"
								/>
							)} */}
            </Col>
            <Col lg={6} className="align-self-center px-3">
              <h5 className="text-white text-400">
                The Finnovex Awards recognises and appreciates the effort of
                various thought-leaders, institutions and service providers
                within the financial services industry. Ultimately, we thrive to
                honour the pioneers and visionaries who have transformed the
                financial services industry.
              </h5>
              <h5 className="text-white text-400">
                <b>May 24, 2021</b>
              </h5>
              <Button
                className="px-3 py-3 mt-5 text-center"
                color="primary"
                size="lg"
                outline
                href="/awards"
              >
                <h3 className="m-0 text-700" style={{ color: "#fff" }}>
                  Nominate Now
                </h3>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Awards;
