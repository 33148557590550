import React from "react";
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";

function WhyAttend() {
  // const [isOpen, setIsOpen] = React.useState(false);
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  // const toggle = i => setIsOpen(i);
  console.log(waContent);
  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
      }}
      className='section p-5'
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className='text-700 mb-4 border-bottom border-info'>
              Why Attend
            </h2>
            <h5 className='text-400 mb-4'>
              As an Industry Pioneer in launching a successful virtual AI event
              platform, it is our priority to ensure our virtual audience has
              the best networking opportunities & connection to authentic
              content at<b> Finnovex Europe 2022!</b>
              <br />
              This Edition will be an exclusive opportunity for accelerated
              Banking and Financial Services solutions hub to
            </h5>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {waContent.map((data, index) => (
            <Col lg={6} key={index} className='mb-4'>
              <div className='slide-container'>
                <img
                  src={require(`assets/images/delegates/${
                    size ? data.image : data.image
                  }`)}
                  width='100%'
                  alt='about'
                  className='image'
                />
                <div className='overlay'>
                  <div className='text text-center'>
                    {size ? (
                      <h3 className='text-700'>
                        {data.title}{" "}
                        <i className='fa fa-arrow-right' aria-hidden='true'></i>
                      </h3>
                    ) : (
                      <h4 className='text-700'>
                        {data.title}
                        <i className='fa fa-arrow-right' aria-hidden='true'></i>
                      </h4>
                    )}
                    {size ? (
                      <h5 className='text-400 text-text'>{data.text}</h5>
                    ) : (
                      <h6 className='text-400 text-text'>{data.text}</h6>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row className='justify-content-center'>
          <Button
            href='/register'
            className='btn'
            color='primary'
            size='lg'
            // style={{ width: "90%" }}
          >
            <p className='m-0 text-700'>Register</p>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhyAttend;

const waContent = [
  {
    title: "Link-up with the regional, international banks and fintech",
    image: "1.png",
    text: "executives who are setting the futuristic trends of financial world",
  },

  {
    title: "Figure out how to evaluate, develop, deploy and customize",
    image: "2.png",
    text: " financial technologies to improve your business processes",
  },

  {
    title: "Hear first-hand to customers on their challenges faced",
    image: "3.png",
    text: "across the entire value-chain of financial processes",
  },

  {
    title: "Access key insights lessons",
    image: "4.png",
    text: "learned, valuable case studies and key insights from peers to apply within your operations",
  },

  {
    title: "Fully evaluate and understand",
    image: "5.png",
    text: "how the comprehensive suite of applications can optimize your business needs",
  },

  {
    title: "Set-up experience centres with real solution demos",
    image: "6.png",
    text: "to help your potential clients better understand the latest technology driven approach offered for their market strategy, as they embark the journey of excellence",
  },
];
