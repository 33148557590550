import React from "react";
import { NavItem, Nav, Container, Row, Col } from "reactstrap";

function WhoAttend() {
  const activeTab = 1;

  return (
    <>
      <div
        style={{
          backgroundColor: "#f5f5f5",
        }}
        className="section p-5"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs="12">
              <Nav id="tabs" role="tablist" justified card>
                <NavItem
                  className={
                    activeTab === 2 ? "border rounded-bottom" : "border"
                  }
                >
                  <a
                    className={
                      activeTab === 2 ? "active text-white" : "text-dark"
                    }
                    href="/delegates/#WhoShouldAttend"
                  >
                    <h5 className="p-1 my-4 text-uppercase">
                      <b>Who Should Attend</b>
                    </h5>
                  </a>
                </NavItem>
                <NavItem
                  className={
                    activeTab === 2 ? "border rounded-bottom" : "border"
                  }
                >
                  <a
                    className={
                      activeTab === 2 ? "active text-white" : "text-dark"
                    }
                    href="/delegates/#WhoWillYouMeet"
                  >
                    <h5 className="p-1 my-4 text-uppercase">
                      <b>Who Will You Meet</b>
                    </h5>
                  </a>
                </NavItem>
                <NavItem
                  className={
                    activeTab === 2 ? "border rounded-bottom" : "border"
                  }
                >
                  <a
                    className={
                      activeTab === 3 ? "active text-white" : "text-dark"
                    }
                    href="/delegates/#WhyAttend"
                  >
                    <h5 className="p-1 my-4 text-uppercase">
                      <b>Why Attend</b>
                    </h5>
                  </a>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default WhoAttend;
