import React from "react";
import Navbar from "components/Navbar";
import RegisterForm from "components/Register";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
function Register({ title }) {
  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundColor: "#002245",
          backgroundImage:
            "url(" + require("assets/images/registerbackground.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "8rem 0 1rem 0",
        }}
        className="section"
        data-parallax={true}
      >
        {title ? (
          <>
            <h1
              className={"text-center text-white text-700 mt-0"}
              style={{ zIndex: 10 }}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h1>
            <h6 className={"text-center  text-700 text-white mt-0"}>
              <Link to="/" className={"text-center text-primary text-700 mt-0"}>
                HOME
              </Link>{" "}
              / <span dangerouslySetInnerHTML={{ __html: title }} />
            </h6>
          </>
        ) : (
          ""
        )}
      </div>
      <RegisterForm title={"Request Brochure"} titleHide={true} />
      <Footer />
    </>
  );
}

export default Register;
