import React from "react";

import { Container, Row, Col, Button } from "reactstrap";

function Speaker({ speakers }) {
  return (
    <>
      <div
        style={{
          background: "url(" + require(`assets/images/bg4.png`) + ")",
          // background: "#5a2169",
          backgroundAttachment: "fixed",
          backgroundColor: "rgba(255,255,255,0.8)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflow: "hidden",
        }}
        data-parallax={true}
      >
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.8)",
          }}
          className='section'
        >
          <Container fluid>
            <Container className='py-5'>
              <Row>
                <Col lg={11}>
                  <h5 className='text-600 mt-0'>
                    We would like to present to you our Fully Virtual 2-day
                    conference on FINNOVEX in Europe. Despite the challenges
                    created between every community due to the pandemic, we’re
                    confident in delivering a dynamic experience that fosters
                    interaction among all our attendees through our virtual
                    event with live experience
                  </h5>
                  <br /> <br />
                </Col>
              </Row>
            </Container>

            <Container className=' '>
              <Row>
                <Col lg={9}>
                  <div className='mb-3'>
                    <h5 className='text-900 mt-0 text-i'>
                      AI Powered Matchmaking to facilitate Networking
                    </h5>
                    <h5 className='text-600 mt-0'>
                      Experience a day filled with personalized networking
                      activities designed to build mutual relationships via our
                      virtual community app without the need to exchange
                      physical business card. We are certain that despite the
                      distance created between every community due to the
                      pandemic, you would have a dynamic experience that fosters
                      interaction among all our attendees, attending live
                      online.
                    </h5>
                  </div>
                  <div className='mb-3'>
                    <h5 className='text-900 mt-0 text-i'>
                      Live Content Streaming
                    </h5>
                    <h5 className='text-600 mt-0'>
                      Enjoy quality agenda and all dynamic keynotes, interactive
                      panel discussions, thought-provoking case studies,
                      start-up & tech pioneer presentations by either attending
                      Finnovex Europe virtually from the comfort of your home or
                      office!
                    </h5>
                  </div>
                  <div className='mb-3'>
                    <h5 className='text-900 mt-0 text-i'>Innovation Lounge</h5>
                    <h5 className='text-600 mt-0'>
                      Ever visited a virtual interactive exhibition booth? You
                      would have access to visit our virtual interactive
                      exhibition both, which allows for engaging demos on the
                      best tools and technologies, live chat between sponsors
                      and delegates. You can also schedule meetings and access
                      downloadable content.
                    </h5>
                  </div>
                  <div className='mb-3'>
                    <h5 className='text-900 mt-0 text-i'>
                      An Exciting, Real Time Conversation with your Peers
                    </h5>
                    <h5 className='text-600 mt-0'>
                      Engage and enjoy networking with top industry leaders in
                      the Europe, grab a virtual coffee with us and win goodies!
                      You would be able get involved with Q&A, Polls, use the
                      intuitive chat capability to make social media shout-outs.
                    </h5>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <h1 className='text-900 text-i mb-2'>VIRTUAL BOOTH</h1>
                  <img
                    src={require(`assets/images/backbase.jpg`)}
                    width='100%'
                    alt='icon'
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Speaker;
