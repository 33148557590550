import React from "react";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          background: "url(" + require(`assets/images/bg1.jpg`) + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={8} md={6} sm={12} className='my-auto'>
              <div>
                <h1 className='text-g pb-0 text-white mt-0 text-uppercase'>
                  A Virtual World of Live Experiences
                </h1>
                <h5
                  className='text-400 text-white'
                  style={{
                    letterSpacing: 0,
                    lineHeight: "20px",
                    color: "#010011",
                    textTransform: "none",
                  }}
                >
                  <br />
                  Fintechs have swiftly become the saviours in meeting customer
                  needs that are constantly evolving , especially in Europe.
                  There has been drastic shift in the challenges and
                  consequences for financial services players, and how FinTech’s
                  can tackle the challenges of systemic change. The vital
                  question on everyone’s lip is, will these emerging innovations
                  and technology provide a more inclusive and sustainable
                  financial ecosystem?
                  <br />
                  <br />
                  With the European fintech and banking scenario in waves of
                  remarkable progress and constantly expanding at a large scale
                  to be more accessible and diverse over the last few years.
                  Open Banking has been a growth instigators, which is
                  globalizing the fintech space to address the challenges in
                  regional limitations and cross-border issues. These have been
                  playing the role in promoting financial institutions and
                  fintechs to partner together to provide more innovative,
                  user-friendly solutions for consumers throughout Europe.
                  <br />
                  <br />
                  How is it possible to evolve with this trend?
                  <br />
                  <br />
                  <Button
                    className='px-3 py-2  my-2 rounded-0'
                    color='primary'
                    href='/about'
                  >
                    <p className='m-0 text-700' style={{ color: "#fff" }}>
                      Know More
                    </p>
                  </Button>
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
