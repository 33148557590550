// reactstrap components
import { Container, Col, Row, Button, Modal, ModalBody, ModalHeader } from "reactstrap";

import Anime from "../Anime";

import React, { useState } from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const [scroll, setScroll] = React.useState(true);
  const [modalRegister, setModalRegister] = useState(false);
  const toggleRegister = () => setModalRegister(!modalRegister);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
        setScroll(false);
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setScroll(true);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <div
        className="page-header"
        style={{
          background: "url(" + require(`assets/images/bg2.png`) + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "hidden",
          alignItems: size ? "start" : "center",
          paddingTop: !size && "10rem",
        }}
      >
        <Container style={{ marginTop: size && "6rem" }}>
          <Row className={size ? "align-items-center" : "align-items-end"}>
            <Col md={7} xs={12}>
              {home && scroll && (
                <>
                  <img
                    src={require(`assets/images/logo/eu.png`)}
                    style={{ maxWidth: "390px" }}
                    width="100%"
                    alt="Finnovex"
                  />

                  <h1 className="text-500 text-g mt-0">November 5-6, 2024</h1>
                </>
              )}
            </Col>
            <Col md={5} xs={12} className={`py-2 `}>
              {size ? (
                <>
                  <h2
                    style={{
                      color: "#fff",
                      fontSize: "30px",
                    }}
                    className="text-600 mt-0"
                  >
                    The Leading Summit on Financial Services Innovation and Excellence
                    <br />
                    <br />
                    London United Kingdom
                  </h2>
                  <br />
                </>
              ) : (
                <>
                  <br />
                  <h5
                    style={{
                      color: "#fff",
                      // fontSize: '30px',
                    }}
                    className="text-600-a mt-0 px-3"
                  >
                    The Leading Summit on
                    <br /> Financial Services Innovation and Excellence
                  </h5>
                </>
              )}
            </Col>
            <Col md={7} xs={12} className="mb-1">
              <Container fluid>
                <Row>
                  <Col lg={8} xs={12} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2  my-2 rounded-0"
                      style={{ width: "99%" }}
                      color="primary"
                      // href='/register'
                      onClick={() => toggleRegister()}
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Register
                      </p>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 my-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/request-brochure"
                      outline
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Download{!size && <br />} Brochure
                      </p>
                    </Button>{" "}
                  </Col>
                  <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2  my-2 rounded-0"
                      style={{ width: "99%" }}
                      color="primary"
                      href="/partners"
                      outline
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        Become a{!size && <br />} Sponsor
                      </p>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Anime />

        <Modal isOpen={modalRegister} toggle={toggleRegister} className="modal-dialog-centered">
          <ModalHeader toggle={toggleRegister}>
            <b>REGISTER FOR FINNOVEX EUROPE</b>
          </ModalHeader>
          <ModalBody>
            {/* 100% complimentry registration for banks, insurance asset and wealth
            management companies- 10 Seats left
            <br />
            <br />
            <Button
              className='px-3 py-2  my-2 rounded-0'
              style={{ width: "99%" }}
              color='primary'
              href='https://forms.hubilo.com/eufinnovex'
            >
              <p className='m-0 text-700' style={{ color: "#fff" }}>
                Register Now
              </p>
            </Button>
            <br />
            Complimentary registration is not applicable to technology providers
            and consultants. <a href='/register'>Click here</a> if you wish to
            sponsor the event.
            <br />
            <br />
            For all others
            <br />
            <br /> */}
            {/* <iframe
              src='https://e10836.hubilo.com/ticketing/?iframe=1'
              frameborder='0'
              height='600'
              width='100%'
              title='paid EA'
            ></iframe> */}
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default LandingPageHeader;
