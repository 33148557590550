import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Input,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import Particles from "./Particles";

function Register({ title, titleHide }) {
  const [details, setDetails] = React.useState({
    type: "REGISTER",
    source: "FINNOVEXEU",
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  const onChangeDetails = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);
    fetch("https://us-central1-finnosec.cloudfunctions.net/api/email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        setSuccess(
          "Thank you for your request. We'll get back to you shortly."
        );
      })
      .catch((err) => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#e0455f",
        backgroundImage:
          "linear-gradient(167deg, rgba(230,40,125,1) 21%, rgba(201,168,72,1) 89%)",
        overflow: "hidden",
      }}
      className='section'
    >
      {/* <div className="overlay-secondary"></div> */}
      <Container>
        <Row>
          {!titleHide && (
            <Col lg={12}>
              <h1 className='text-center text-white text-700 pb-4 text-uppercase'>
                {title}
              </h1>
            </Col>
          )}
          <Col lg={6}>
            <h3 className='text-700 text-white mb-5'>
              This Edition will be an exclusive opportunity for accelerated
              Banking and Financial Services solutions hub to -
            </h3>
            <h5 className='text-400 text-white'>
              <ul>
                <li>
                  Link-up with the regional, international banks and fintech
                  executives who are setting the futuristic trends of financial
                  world
                </li>
                <li>
                  Figure out how to evaluate, develop, deploy and customize
                  financial technologies to improve your business processes
                </li>
                <li>
                  Hear first-hand to customers on their challenges faced across
                  the entire value-chain of financial processes
                </li>
                <li>
                  Access key insights lessons learned, valuable case studies and
                  key insights from peers to apply within your operations
                </li>
                <li>
                  Fully evaluate and understand how the comprehensive suite of
                  applications can optimize your business needs
                </li>
                <li>
                  Set-up experience centres with real solution demos to help
                  your potential clients better understand the latest technology
                  driven approach offered for their market strategy, as they
                  embark the journey of excellence
                </li>
                <li>
                  Regional Excellence Awards to recognise and applaud
                  pace-setting industry merit.
                </li>
              </ul>
            </h5>
          </Col>
          <Col className='mx-auto mb-5 py-5' lg={6}>
            <Container className='my-auto text-white text-uppercase text-400'>
              <Form onSubmit={onSubmitForm} className='register-form'>
                <Row>
                  <Col lg={6} className='my-2'>
                    <label>First Name*</label>
                    <Input
                      placeholder='First Name'
                      type='text'
                      name='first_name'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Last Name*</label>
                    <Input
                      placeholder='Last Name'
                      type='text'
                      name='last_name'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className='my-2'>
                    <label>Job Title*</label>
                    <Input
                      placeholder='Job Title'
                      type='text'
                      name='job'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Company*</label>
                    <Input
                      placeholder='Company'
                      type='text'
                      name='company'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Email*</label>
                    <Input
                      placeholder='Email'
                      type='text'
                      name='email'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>

                  <Col lg={6} className='my-2'>
                    <label>Phone*</label>
                    <Input
                      placeholder='Phone'
                      type='text'
                      name='phone'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>City*</label>
                    <Input
                      placeholder='City'
                      type='text'
                      name='city'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Country*</label>
                    <Input
                      placeholder='Country'
                      type='text'
                      name='country'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Interested In</label>
                    <Input
                      type='select'
                      name='interest'
                      id=''
                      onChange={onChangeDetails}
                      required
                    >
                      <option>Select</option>
                      <option>Delegate Pass</option>
                      <option>Speaking Opportunity</option>
                      <option>Sponsoring the Summit</option>
                      <option>Booking an Exhibition Space</option>
                      <option>Media Partnership</option>
                    </Input>
                  </Col>
                  <Col lg={6} className='my-2'>
                    <label>Where did you hear about us?</label>
                    <Input
                      type='select'
                      name='heard_from'
                      id=''
                      onChange={onChangeDetails}
                    >
                      <option>Select</option>
                      <option>Email</option>
                      <option>LinkedIn</option>
                      <option>Twitter</option>
                      <option>Facebook</option>
                      <option>Instagram</option>
                      <option>News/Other Websites</option>
                      <option>Referral</option>
                    </Input>
                  </Col>
                  <Col lg={12} className='my-2'>
                    <label>
                      Which other country/region would you like us to host
                      FINNOVEX?
                    </label>
                    <Input
                      placeholder='Country'
                      type='text'
                      name='country'
                      onChange={onChangeDetails}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg='12' className='mt-3'>
                    By submitting this form, I provide my consent to Exibex
                    (Organizer) to send me latest updates via email/Phone call/
                    with information related to our series of summit and I
                    provide my consent that by registering as a delegate, The
                    details shared pertaining not limited to your email and
                    other contact details with Exibex , Event Organiser has your
                    consent in sharing details with their partners and sponsors.
                  </Col>
                </Row>
                <Button
                  block
                  className='btn text-white text-700 mt-2'
                  color='info'
                  size='lg'
                  type='submit'
                  disabled={loading}
                >
                  Submit
                  {loading ? (
                    <span>
                      {" "}
                      <Spinner color='warning' size='sm' />
                    </span>
                  ) : (
                    ""
                  )}
                </Button>
              </Form>
            </Container>
            {error ? (
              <Alert
                color='danger'
                isOpen={true}
                fade={true}
                className='text-center'
                style={{ marginTop: "1rem" }}
              >
                {error}
              </Alert>
            ) : (
              ""
            )}
            {success ? (
              <Alert
                color='success'
                isOpen={true}
                fade={true}
                className='text-center'
                style={{ marginTop: "1rem" }}
              >
                {success}
              </Alert>
            ) : (
              ""
            )}
            {/* </Card> */}
          </Col>
        </Row>
      </Container>
      {/* <Particles /> */}
    </div>
  );
}

export default Register;
