import React from "react";
import LandingPageHeader from "components/Headers/LandingPageHeader";
import TextGrid from "components/TextGrid";
import Event from "components/Event";
import Exibex from "components/Exibex";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Speaker from "components/Speaker";
import Spotlight from "components/Spotlight";
import Sponsor from "components/Sponsor";
import Price from "components/Price";
import Stats from "components/Stats";
import Awards from "components/Awards";
import Register from "components/Register";
import Hybrid from "components/Hybrid";

import { sponsorsPage, spotlights, speakers } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function Home() {
  return (
    <div id="outer-container">
      <Navbar page="home" home />
      <ScrollUpButton />
      <LandingPageHeader home />
      <TextGrid />
      <Hybrid />
      <Spotlight spotlights={spotlights} />
      <Stats />
      <section id="speakers" />
      <Speaker speakers={speakers} />
      <Event />
      <Awards />
      <section id="partners" />
      <Sponsor sponsors={sponsorsPage} />
      {/* <Price /> */}
      <section id="register" />
      <Register title={"Register"} />
      <Exibex />
      <Footer />
    </div>
  );
}

export default Home;
