import React from "react";

import { Container, Row, Col } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          background: "#fff",
        }}
        className='section'
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className='text-400 text-center text-muted'>
                Finnovex Awards Europe 2021 Winners
              </h2>
            </Col>
          </Row>
          <Row className='pt-5'>
            {content.map((data) => (
              <Col lg={6} key={data} className='py-3'>
                <img
                  alt='...'
                  className='img rounded-0 img-responsive'
                  width='100%'
                  src={require(`assets/images/awards/${data}`)}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "1.jpeg",
  "2.jpeg",
  "3.jpeg",
  "4.jpeg",
  "5.jpeg",
  "6.jpeg",
  "7.jpeg",
  "8.jpeg",
  "9.jpeg",
  "10.jpeg",
  "11.jpeg",
  "12.jpeg",
  "13.jpeg",
  "14.jpeg",
  "15.jpeg",
];
