import React from "react";
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";

function WhyAttend() {
  // const [isOpen, setIsOpen] = React.useState(false);

  // const toggle = i => setIsOpen(i);
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
      className='section p-5'
      data-parallax={true}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className='text-700 mb-4 border-bottom border-info'>
              Who Will You Meet
            </h2>
            <h5 className='text-400 mb-4'>
              Finnovex will include participants from: Meet and Network with{" "}
              <b>
                C-Level’s, Senior Vice Presidents, Vice Presidents, Directors,
                and Heads of departments, etc of
              </b>
            </h5>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          {wsaContent.map((data, index) => (
            <Col lg={4} key={index}>
              <Card>
                <CardBody>
                  <Container>
                    <Row>
                      <Col
                        xs={3}
                        className=' py-3'
                        style={{ borderRight: "3px solid #ee3388" }}
                      >
                        <img
                          src={require(`assets/images/icons/${data.image}`)}
                          alt=''
                          width='100%'
                          style={{ maxWidth: "100px" }}
                        />
                      </Col>
                      <Col xs={9} className='align-self-center'>
                        <h5 className='text-700 m-0'>{data.text}</h5>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Row className='justify-content-center'>
          <Button
            href='/register'
            className='btn'
            color='primary'
            size='lg'
            // style={{ width: "90%" }}
          >
            <p className='m-0 text-700'>Register</p>
          </Button>
        </Row>
      </Container>
    </div>
  );
}
export default WhyAttend;

const wsaContent = [
  { text: "Regulators", image: "41.png" },
  { text: "Central Banks", image: "42.png" },
  { text: "Commercial Banks", image: "43.png" },
  { text: "Investment Banks", image: "44.png" },
  { text: "Retail Banks", image: "45.png" },
  { text: "Insurance Companies", image: "46.png" },
  { text: "Digital/Neo Banks", image: "47.png" },
  { text: "Telco Operators", image: "48.png" },
  { text: "FinTech’s and E- Commerce Organizations", image: "49.png" },
];
