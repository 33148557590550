export const whosponsor = [
  {
    id: 1,
    title: 'Thought Leadership',
    description: [
      'As a sponsor at Finnovex, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.',
    ],
    location: 'left',
    image: '1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY',
  },
  {
    id: 2,
    title: 'PREMIUM BRANDING',
    description: [
      'We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ',
      'As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, MEDIA PARTNERShips and social media like how we have built within a year brand like Finnovex Global Series in Middle East and Africa.Find out more about team working on Finnovex who successfully executed Finnovex Global Series www.finnovex.com',
    ],
    location: 'right',
    image: '1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9',
  },
  {
    id: 3,
    title: 'FEATURED NETWORKING EVENTS / FACE TIME:',
    description: [
      'Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.',
      'Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events,we have successfully Introduced more than 100+ Banks,FI meetings with our sponsors find out more on our YouTube Channel.',
    ],
    location: 'left',
    image: '1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ',
  },
];

export const spotlights = [
  { title: 'Digitisation– Future of Financial Inclusion', image: '1.png' },
  { title: 'Creating a Cashless Economy', image: '2.png' },
  { title: 'Customer Experience and Digital Transformation', image: '3.png' },
  { title: 'Trends and Challenges in the Digital Only Model', image: '4.png' },
  {
    title: 'Implications of COVID-19 on the BFSI industry in Europe',
    image: '5.png',
  },
  { title: 'Microfinance – The all-inclusive model', image: '6.png' },
  {
    title: 'Future of Islamic Banking and Islamic Microfinance',
    image: '7.png',
  },
  { title: 'AI in Security', image: '4.png' },
  { title: 'ID and Access Management', image: '2.png' },
];
export const sponsors = [
  {
    name: 'Digital Asset',
    image: 'digi.png',
    type: 'PLATINUM SPONSOR',
    link: 'https://www.digitalasset.com/',
    grid: 6,
    text: '',
  },
  {
    name: 'Smartstream',
    image: 'smartstream.png',
    type: 'PLATINUM SPONSOR',
    link: 'https://www.smartstream-stp.com/',
    grid: 12,
    text: 'SmartStream is a recognised leader in financial transaction management solutions that enables firms to improve operational control, reduce costs, build new revenue streams, mitigate risk and comply accurately with regulations. By helping its customers through their transformative digital strategies, SmartStream provides a range of solutions for the transaction lifecycle with artificial intelligence and machine learning technologies embedded – which can also be deployed in the cloud or as managed services. As a result, more than 2,000 clients—including 70 of the world’s top 100 banks, rely on SmartStream Transaction Lifecycle Management (TLM®) solutions to deliver greater efficiency to their operations.',
  },

  {
    name: 'Backbase',
    image: 'backbase.jpeg',
    type: 'GOLD SPONSOR',
    link: 'http://www.backbase.com/',
    grid: 6,
    text: 'Backbase is a fast-growing fintech software provider that empowers financial institutions to accelerate their digital transformation and effectively compete in a digital-first world. We are the creators of the Backbase Omni-Channel Banking Platform, a state-of-the-art digital banking software solution that unifies data and functionality from traditional core systems and new fintech players into a seamless digital customer experience. We give financials the speed and flexibility to create and manage seamless customer experiences across any device and deliver measurable business results. We believe that superior digital experiences are essential to stay relevant, and our software enables financials to rapidly grow their digital business.',
  },
  {
    image: 'Yellow.ai ( Logo)  (1).jpg',
    type: 'BRONZE SPONSOR',
    grid: 4,
    smallGrid: 12,
    link: 'https://yellow.ai/',
  },
  {
    name: 'SmartMessage',
    image: 'smartmessage.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.smartmessage.com/',
    grid: 6,
    text: '',
  },
  {
    name: 'abaka',
    image: 'abaka.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: `ABAKA is the global leader in digital saving and
      retirement enterprise SaaS solutions, powered by
      Artificial Intelligence. The ABAKA modular platform,
      leveraging new technology (Artificial Intelligence,
      open banking APIs, omni-channel engagement) is a
      great tool kit to help legacy FIs to keep up and
      fintechs to hit the ground running. The cloud-based
      platform and library of modular applications helps
      clients build highly differentiated digital solutions
      and a truly personalised customer experience. 
      
      ABAKA has built the economy applications and
      seamless data exchange platform enabling FIs to
      access customer data, build insights and intelligence
      through conversational AI, Next Best Actions and
      hyper-personalised behavioural nudges. We provide
      digital solutions covering saving and retirement
      (accumulation, at-retirement and decumulation),
      banking (PFM, short term digital savings), wealth
      and advisory (goal-based investing, data
      aggregation, advisor lead gen tools and business
      process automation).`,
    link: 'https://abaka.me/',
    grid: 6,
  },
  {
    name: 'Creatio',
    image: 'creatio.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.creatio.com',
    grid: 6,
    text: `Creatio is a global software company providing a leading low-code platform for process
    management and CRM. The company has been highly recognized as a market leader by key
    industry analysts. Its intelligent products accelerate sales, marketing, service and operations
    for mid-size and large enterprises. Together with hundreds of partners Creatio operates in 110
    countries worldwide. The mission of Creatio is to help companies ACCELERATE!`,
  },

  {
    name: 'SME FINANCE',
    image: 'sme.jpg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.smefinanceforum.org/',
    grid: 6,
    text: '',
  },
  {
    name: 'COLLABRAT [H} ER',
    image: 'collab.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.collaborat-her.com/',
    grid: 6,
    text: '',
  },
  {
    name: 'Central bank of Nigeria',
    image: 'cbon.png',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'EDB Bahrain',
    image: 'edb.png',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'expo',
    image: 'expo.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 2,
  },
  {
    name: 'difc',
    image: 'difc.jpg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 2,
  },

  {
    name: 'onespan',
    image: 'onespan.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: 'OneSpan helps protect the world from digital fraud by establishing trust in people’s identities, the devices they use and the transactions they carry out. We do this by making digital banking accessible, secure, easy and valuable. OneSpan’s Trusted Identity platform and security solutions significantly reduce digital transaction fraud and enable regulatory compliance for more than 10,000 customers, including over half of the top 100 global banks. Whether through automating agreements, detecting fraud or securing financial transactions, OneSpan helps reduce costs and accelerate customer acquisition while improving the user experience. Learn more at OneSpan.com.',
    link: 'https://www.onespan.com/logos',
    grid: 6,
  },

  {
    name: 'consdata',
    image: 'consdata.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://consdata.com/',
    grid: 6,
    text: 'Consdata is a modern and agile software development company based in Poznań, Poland, specializing in servicing banks, financial institutions and governmental organizations. We currently work with seven major banks, including subsidiaries of Commerzbank, Santander, Societe Generale, Credit Agricole as well as two associations of Polish co-operative banks, consisting altogether of 560 community banking organizations. In addition, we have service agreements in place with the leading e-commerce company and cable television operator on the Polish market.',
  },

  {
    name: 'Comarch',
    image: 'comarch.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'http://www.comarch.com',
    grid: 6,
    text: 'Comarch is a global provider of IT business solutions that aim to optimize operational and business processes. Since its inception in 1993, we have developed at a rapid pace. Today, Comarch is the leading IT company in Central & Eastern Europe and employs over 6400 experienced IT engineers and business consultants. Execution of over 2000 IT projects and implementation of our software in more than 40,000 companies worldwide is a source of great pride.',
  },
  {
    name: 'Infobip',
    image: 'infobip.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.infobip.com/',
    grid: 6,
    // a: 1,
    text: 'Infobip powers enterprises to deliver messages across any channel, any device, at anytime and anywhere worldwide. Infobip’s technology creates seamless mobile interactions between businesses and people and simplifies the integration of almost all communication capabilities. With over a decade of industry experience, Infobip has expanded to include 65 offices on six continents offering in-house developed messaging platform with the capacity to reach over seven billion mobile devices in 190+ countries connected to over 800 telecom networks. The company serves and partners with leading mobile operators, messaging apps, banks, social networks and tech companies.',
  },
  {
    name: 'PAYONEER',
    image: 'Payoneer.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.payoneer.com',
    grid: 6,
    // a: 1,
    text: 'Payoneer’s mission is to empower businesses to go beyond – beyond borders, limits and expectations. In today’s digital world, Payoneer enables any business of any size from anywhere to access new economic opportunities by making it possible to transact as easily globally as they do locally. Payoneer’s digital platform streamlines global commerce for millions of small businesses, marketplaces and enterprises from 200 countries and territories. Leveraging its robust technology, compliance, operations and banking infrastructure, Payoneer delivers a suite of services that includes cross-border payments, working capital, tax solutions and risk management. Powering growth for customers ranging from aspiring entrepreneurs in emerging markets to the world’s leading digital brands like Airbnb, Amazon, Google and Upwork, Payoneer makes global commerce easy and secure. Founded in 2005, Payoneer is profitable and has a team based all around the world. Payoneer - one world, one platform, unlimited opportunities.',
  },
  {
    name: 'TRIPWIRE',
    image: 'tripwire.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.tripwire.com/',
    grid: 6,
    // a: 1,
    text: 'For over 20 years Tripwire has specialized in finding and minimizing a wide range of cyber security threats both on-site and in the cloud. Our portfolio provides the most critical security controls covering unauthorized changes, misconfigurations, vulnerabilities, and compliance issues. We provide solutions for IT and Industrial and have both SaaS and Managed Service options.',
  },

  {
    name: 'Coforge',
    image: 'coforge.jpg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.coforgetech.com/',
    grid: 12,
    a: 1,
    text: `Coforge is a leading global IT solutions organization, enabling its clients to transform at the intersect of unparalleled domain expertise and emerging technologies to achieve real-world business impact. A focus on very select industries, a detailed understanding of the underlying processes of those industries, and partnerships with leading platforms provide us a distinct vantage. We leverage AI, Cloud, and Insight-driven technologies, allied with our industry expertise, to transform client businesses into intelligent, high growth enterprises. Today our proprietary platforms power critical business processes across the Financial Services and Travel industries. Our 11,000 technology and process consultants engineer, design, consult, operate, and modernize systems across the world.`,
  },

  {
    name: 'VACCUMLABS',
    image: 'Vaccumlabs.jpg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.vacuumlabs.com/vacuumlabs-we-put-the-tech-in-fintech',
    grid: 12,
    a: 1,
    text: 'Vacuumlabs is a fintech-focused software house full of experienced product builders. It provides teams of agile, remote by nature, software engineers, and designers with years of experience building fintech, cryptocurrency, online marketplaces, and digital products. Every step of the way, their experts guide their clients, ensuring they can most fast, learn, and iterate. When it comes to world-class product development, they are here to be your guide and partner.',
  },
  {
    name: 'Neosoft',
    image: 'Neosoft.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'http://www.neosofttech.com/',
    grid: 12,
    a: 1,
    text: "NeoSOFT is an SEI-CMMI Level-5 and ISO 9001:2008 certified global IT consulting & software solutions provider with 2000+ software consultants working full time across 7 Delivery Centers. Established in 1996, NeoSOFT is headquartered in Mumbai, India with offices in the USA, UK, Dubai, Italy, Germany, Japan & Australia. Our commitment to quality and 23+ years of experience has made us serve over 1500+ clients across 50+ countries with 85% client retention. They build cohesive technology solutions for the World’s Leading Enterprises, SMEs & Startups and ISV's We cater to more than 22+ Industries like  Banking & Finance - E-Commerce & Retail - Education & E-Learning - Government/ Semi-Government Healthcare - Insurance - IT & Consulting - Manufacturing - Media & Entertainment to name a few.",
  },
  {
    name: 'HyperPay',
    image: 'hyperpay.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: 'https://www.hyperpay.com/',
    grid: 12,
    a: 1,
    text: 'HyperPay is the fastest growing Middle East-based payment gateway, transforming the online buying experience in the MENA region. Since its launch in 2014, HyperPay has enabled +1000 internet businesses to accept and manage payments online, with more flexibility, security and ease. Today, HyperPay continues to quickly expand its products and services to meet the online payment needs of businesses, ranging from the smallest to the largest enterprises. Get started with HyperPay and start accepting payments in local currencies via local acquirers across KSA, UAE, Bahrain, Jordan, Egypt, and Lebanon.',
  },
  {
    name: 'newgen',
    image: 'newgen.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'oracle',
    image: 'oracle.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 2,
  },
  {
    name: 'onespan',
    image: 'onespan.jpeg',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 2,
  },
  {
    name: 'comviva',
    image: 'comviva.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'Daon',
    image: 'daon.jpg',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'Temenos',
    image: 'temenos.jpg',
    link: 'https://www.temenos.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: 'Temenos, headquartered in Geneva, is the world leader in banking software, partnering with banks and other financial institutions to transform their businesses and stay ahead of a changing marketplace. Over 3,000 firms across the globe, including 41 of the top 50 banks, rely on Temenos to process both the daily transactions and client interactions of more than 500 million banking customers. Temenos offers cloud-native, cloud-agnostic front office and core banking, payments, fund management and wealth management software products enabling banks to deliver consistent, friction-less customer journeys and gain operational excellence. Our vision is to provide financial institutions, of any size, anywhere in the world, the software to thrive in the digital banking age.',
  },
  {
    name: 'fss',
    image: 'fss.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'openfactor',
    image: 'openfactor.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'maxut',
    image: 'maxut.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'piotech',
    image: 'piotech.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },

  {
    name: 'PWC',
    image: 'pwc.jpg',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },

  {
    name: 'assertify',
    image: 'assertify.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'avaya',
    image: 'avaya.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },

  {
    name: 'netcore',
    image: 'netcore.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },

  {
    name: 'Bankserv Africa',
    image: 'bankserv.jpg',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'infrasoft',
    image: 'infrasoft.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'yethi',
    image: 'yethi.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },
  {
    name: 'Rubrik',
    image: 'rubrik.jpg',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'turbonomic',
    image: 'turbonomic.png',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    link: '',
    grid: 6,
    a: 1,
  },

  {
    name: 'TCS',
    image: 'tcs.jpg',
    link: '',
    type: 'PAST SERIES SPONSORS AND PARTNERS',
    text: false,
  },
  {
    name: 'The Power 50',
    image: 'fintechpower.png',
    link: 'https://www.thepower50.com/',
    type: 'MEDIA PARTNERS',
    text: false,
  },

  {
    name: 'BeinCrypto',
    image: 'beingcrypto.jpg',
    link: 'https://beincrypto.com/',
    type: 'MEDIA PARTNERS',
    text: false,
  },
  {
    name: 'ibm',
    image: 'ibm.jpeg',
    type: 'MEDIA PARTNERS',
    link: 'https://intlbm.com/',
    grid: 3,
  },
  {
    name: 'Cointelegraph',
    image: 'cointelegraph.png',
    link: 'https://cointelegraph.com/',
    type: 'MEDIA PARTNERS',
    text: false,
  },

  {
    name: 'The Fintech Times',
    image: 'fintechtimes.png',
    link: 'https://thefintechtimes.com/',
    type: 'MEDIA PARTNERS',
    text: false,
  },
  {
    name: 'fintechna',
    image: 'fintechna.png',
    link: 'https://www.fintechna.com/',
    type: 'MEDIA PARTNERS',
    text: false,
  },

  {
    name: 'Industry Events',
    image: 'ie.png',
    link: 'http://www.industryevents.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'innovationtech.png',
    image: 'innovationtech.png',
    link: 'https://innotechtoday.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'digitalscouting',
    image: 'digitalscouting.png',
    link: 'https://www.digitalscouting.de/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'koinpost',
    image: 'koinpost.png',
    link: 'https://koinpost.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'mobileidworld',
    image: 'mobileidworld.png',
    link: 'https://mobileidworld.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'mysecurity',
    image: 'mysecurity.png',
    link: 'https://mysecuritymedia.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'oxford',
    image: 'oxford.png',
    link: 'https://oxfordbusinessgroup.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'btchaber',
    image: 'btchaber.png',
    link: 'https://www.btchaber.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'coinbilgi',
    image: 'coinbilgi.png',
    link: 'https://www.coinbilgi.net/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'Coin Republic',
    image: 'coinrepublic.png',
    link: 'https://www.thecoinrepublic.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'financialIT',
    image: 'financialIT.png',
    link: 'https://financialit.net/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'financialnigeria',
    image: 'financialnigeria.png',
    link: 'http://www.financialnigeria.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'findbiometrics',
    image: 'findbiometrics.png',
    link: 'https://findbiometrics.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'holland',
    image: 'holland.png',
    link: 'https://hollandfintech.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'residentialtech',
    image: 'residentialtech.png',
    link: 'https://restechtoday.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'security',
    image: 'security.png',
    link: 'https://www.securitymiddleeastmag.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'startupnews',
    image: 'startupnews.png',
    link: 'https://www.startupnews.fyi/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'Security & Fire Africa',
    image: 'sfa.jpg',
    link: 'https://securityafricamagazine.com/',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
  {
    name: 'Cryptonewz',
    image: 'cryptonewz.jpeg',
    link: 'https://www.cryptonewsz.com',
    type: 'MEDIA PARTNERS',
    text: false,
    grid: 3,
  },
];

const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy('type');
export const sponsorsPage = groupByType(sponsors);

export const live = [
  {
    id: 1,
    link: 'Is-banking-security-in-Africa-holding-fintech-back',
    description: 'Is banking security in Africa holding fintech back?',
    content:
      'New figures released by the SABRIC, revealed a huge spike in credit card fraud, up an eyewatering 18% year-on-year',
    image: '1.jpg',
  },
  {
    id: 2,
    link: 'Will-Biometric-Technology-Take-Over-Banking-Security',
    description: 'Will Biometric Technology Take Over Banking Security?',
    content:
      'If you haven’t heard the news, more banks are adopting biometric technology for customer authentication to increase security',
    image: '2.jpg',
  },
  {
    id: 3,
    link: 'Africa-is-leaving-itself-dangerously-exposed-to-cyber-attacks',
    description: 'Africa is leaving itself dangerously exposed to cyber attacks',
    content:
      'In their rush to embrace the digital future, African entities are leaving themselves dangerously exposed to cyber attacks',
    image: '3.jpg',
  },
];
export const speakers = [
  {
    name: 'Naveed Sultan ',
    title: 'Chairman & Managing Director Institutional Client Group',
    company: 'Citi, United Kingdom',
    image: 'naveed.jpg',
    companyImage: 'citi logo.jpg',
    link: '',
  },

  {
    name: 'Daniela Bobocea',
    title: 'Chief Digital Officer',
    company: 'Eximbank Group,Romania',
    image: 'Daniela Bobocea.jpg',
    companyImage: 'EB-logo.png',
    link: '',
  },

  {
    name: 'Vusal Khalilov',
    title: 'Chief Technology Officer',
    company: 'Pasha Bank, Azerbaijan',
    image: 'Vusal Khalilov Photo.jpg',
    companyImage: 'Pasha Bank logo.png',
    link: '',
  },

  {
    name: 'Stephane Malrait',
    title:
      'Managing Director- Global Head of Market Structure and Innovation for Financial Markets',
    company: 'ING Bank, United Kingdom',
    image: 'malrait.jpg',
    companyImage: 'ing.jpg',
    link: 'https://www.linkedin.com/in/smalrait/?originalSubdomain=uk',
  },

  {
    name: 'Constantin Mareș',
    title: 'Chief Digital Officer',
    company: 'OTP Bank, Romania',
    image: 'costantine.jpg',
    companyImage: 'otp.png',
    link: 'https://www.linkedin.com/in/constantin-mare%C8%99-9b6278/',
  },
  {
    name: 'Antonios Vourakis',
    title: 'Chief Digital and Retail Officer',
    company: 'Pancreta Bank, Greece',
    image: 'Antonios.jpg',
    companyImage: 'pancreta.png',
    link: '',
  },
  {
    name: 'Tamás Fodor',
    title: 'Member of the Board, Retail Banking',
    company: 'Sber Bank, Hungary',
    image: 'fodor.jpg',
    companyImage: 'sberbank.jpg',
    link: '',
  },
  // {
  //   name: 'Magdalena Nowicka',
  //   title: 'Vice President Of The Management Board',
  //   company: 'BNP Paribas Bank, Polska',
  //   image: 'Magda Nowicka Photograph.jpg',
  //   companyImage: 'BNP Paribas.jpg',
  //   link: '',
  // },
  {
    name: 'Margus Simson',
    title: 'Chief Digital Officer',
    company: 'Komerční Banka, Estonia',
    image: 'Margus Simson.jpg',
    companyImage: 'komercni-banka logo.jpg',
    link: '',
  },
  // {
  //   name: 'George Panou',
  //   title: 'Head Of Innovation Centre',
  //   company: 'Eurobank, Greece',
  //   image: 'George Panou.jpg',
  //   companyImage: 'Eurobank-RGB-bleu.png',
  //   link: '',
  // },
  // {
  //   name: 'Susana Delgado',
  //   title: 'Digital Strategy & Director',
  //   company: 'PagoNxt, Banco Santander, Spain',
  //   image: 'Susana Delgado picture3.jpg',
  //   companyImage: undefined,
  //   link: '',
  // },

  {
    name: 'Peter Rossiter ',
    title: 'Chief Risk Officer',
    company: 'Starling International, Ireland',
    image: 'peter.png',
    companyImage: 'starling.jpg',
    link: 'https://www.linkedin.com/in/peter-rossiter-a9782912/',
  },
  {
    name: 'Sławomir Soszyński',
    title: 'Chief Information Officer',
    company: 'ING Bank Śląski S.A.',
    image: 'Soszyński.jpg',
    companyImage: 'ING logo.jpg',
    link: '',
  },
  {
    name: 'Aysenur Hickiran ',
    title: 'EVP, Member of Management Board, Head of Retail Banking and Wealth Management Group ',
    company: 'DenizBank, Turkey',
    image: 'Aysenur.jpg',
    companyImage: 'deniz.png',
    link: '',
  },
  {
    name: 'Marcin Krzyżanowski',
    title: 'Head of CEE  ',
    company: 'Backbase, Netherlands',
    image: 'speaker.png',
    companyImage: 'backbase.jpeg',
    link: '',
  },
  {
    name: 'David Gyori',
    title: 'CEO',
    company: 'Top 50 Global Thought Leader and Influencer in Digital Transformation',
    image: 'David Gyori Photo.jfif',
    companyImage: undefined,
    link: '',
  },
  {
    name: 'Dr. Konstantinos Tsanis ',
    title: 'Digital Transformation and FinTech Specialist',
    company: 'IFC, United States',
    image: 'Konstantin Tsanis.jpeg',
    companyImage: undefined,
    link: '',
  },
  {
    name: 'Michael Salmony',
    title: 'CEO',
    company: 'Payments Innovation Consultant',
    image: 'Michael Salmony Photo.jpg',
    companyImage: undefined,
    link: '',
  },
  {
    name: 'Radu Topliceanu',
    title: 'Deputy CEO and Head or Retail Banking',
    company: 'BRD - Groupe Societe Generale',
    image: 'Radu_Topliceanu_BRD (1).jpg',
    companyImage: 'brd.png',
    link: '',
  },
  {
    name: 'Viktoriya V Blazheva',
    title: 'FVP, Strategy and Business Officer',
    company: 'UniCredit Bulbank',
    image: 'Viktoriya J Blazjheva.jpg',
    companyImage: undefined,
    link: '',
  },

  {
    name: 'Ekaterina Marinova',
    title: 'Data and Innovation Strategist',
    company: 'Raiffeisenbank Bulgaria',
    image: 'Ekaterina Photo.jpg',
    companyImage: undefined,
    link: '',
  },
  {
    name: 'Suzana Stojakovic-Celustka',
    title: 'Company Owner at InfoSet , CISO & DPO Deputy',
    company: 'HBOR',
    image: 'Suzana.jpg',
    companyImage: undefined,
    link: '',
  },

  {
    name: 'Valeria Zafar ',
    title: 'Director for EMEA Business Development in Capital Markets',
    company: 'Digital Asset',
    image: 'Valeria Photo.jpg',
    companyImage: "digi (1).jpg",
  },

  {
    name: 'Roland Brandli',
    title: 'Strategic Product Manager',
    company: 'Smartstream Technologies, UAE',
    image: 'Roland Headshot.jpg',
    companyImage: "smartstream.jpeg",
  },

  {
    name: 'Konica Khandelwal Dhawan ',
    title: 'GM Global Banking (EU, APAC, MEA) and VP Sales ',
    company: 'Yellow. ai, UAE',
    image: 'Konica Khandelwal.png',
    companyImage: "yellow.jpeg",
  },
];
export const pastspeakers = [
  {
    name: 'Tony McLaughlin',
    title: 'Managing Director Transaction Banking',
    company: 'CitiBank, United Kingdom',
    image: 'tony.jpg',
    companyImage: 'citi.png',
    link: 'https://www.linkedin.com/in/tony-mclaughlin-7b627a3/?originalSubdomain=uk',
  },
  {
    name: 'Stephane Malrait',
    title:
      'Managing Director- Global Head of Market Structure and Innovation for Financial Markets',
    company: 'ING Bank, United Kingdom',
    image: 'malrait.jpg',
    companyImage: 'ing.jpg',
    link: 'https://www.linkedin.com/in/smalrait/?originalSubdomain=uk',
  },
  {
    name: 'Aieti G Kukava',
    title: 'Chief Executive Officer',
    company: 'Alliance Group Capital',
    image: 'Aieti.jpg',
    companyImage: 'agc.png',
    link: 'https://www.linkedin.com/in/aietigeorge/',
  },
  {
    name: 'Tamas Fodor',
    title: 'Member of the Board, Retail Banking',
    company: 'Sberbank, Hungary',
    image: 'fodor.jpg',
    companyImage: 'sberbank.jpg',
    link: 'https://www.linkedin.com/in/tam%C3%A1s-fodor-a46a4946/?originalSubdomain=hu',
  },
  {
    name: 'Radu Topliceanu',
    title: 'Deputy CEO and Head, Retail Banking',
    company: 'BRD – Groupe Société Générale',
    image: 'radu.jpg',
    companyImage: 'brd.png',
    link: 'https://www.linkedin.com/in/radutopliceanu/?originalSubdomain=ro',
  },
  {
    name: 'Pedro Pinto Coelho',
    title: 'Chairman',
    company: 'Portuguese Fintech and Insurtech association, AFIP ',
    image: 'pedro.jpg',
    companyImage: 'bancobni.png',
    link: 'https://www.linkedin.com/in/pedropintocoelho/?originalSubdomain=pt',
  },
  {
    name: 'Alfonso Ayuso',
    title: 'Former Chief Innovation Officer - Banco Sabadell ',
    company: 'Exponential Coach & Trainer (ExO)',
    image: 'alfonso.jpg',
    companyImage: 'sabadell.png',
    link: 'https://www.linkedin.com/in/alfonso-ayuso-500331110/?originalSubdomain=es',
  },
  {
    name: 'Alvin Alicevic',
    title: 'Member of the Management Board (CRO & COO)',
    company: 'Sparkasse Bank, Macedonia',
    image: 'alvin.jpg',
    companyImage: 'sparkasse.png',
    link: 'https://www.linkedin.com/in/alvin-alicevic-86866b123/?originalSubdomain=mk',
  },
  // {
  //   name: "Szymon Walach",
  //   title: "Managing Director Strategy and Digital Transformation Division",
  //   company: "PKO Bank Polski, Poland ",
  //   image: "szymon.jpg",
  //   companyImage: "pko.png",
  //   link:
  //     "https://www.linkedin.com/in/szymon-walach-4a6311/?originalSubdomain=pl",
  // },

  {
    name: 'Jenny Winther',
    title: 'Secretary General',
    company: 'Nordic Payments Council, Sweden',
    image: 'jenny.jpg',
    companyImage: 'npc.png',
    link: 'https://www.linkedin.com/in/jenny-winther-npc/',
  },
  {
    name: 'Thomas Krogh Jensen',
    title: 'Chief Executive Officer (CEO)',
    company: 'Copenhagen FinTech',
    image: 'thomas.jpg',
    companyImage: 'cf.png',
    link: 'https://www.linkedin.com/in/thomaskroghjensen/?originalSubdomain=dk',
  },

  {
    name: 'Matthew Gamser',
    title: 'Chief Executive Officer SME Finance Forum',
    company: 'International Finance Corporation, United States',
    image: 'matthew.jpg',
    companyImage: 'ifc.jpg',
    link: 'https://www.linkedin.com/in/matthew-gamser-a3580110/',
  },
  {
    name: 'Antonios Vourakis',
    title: 'Chief Digital and Retail Officer',
    company: 'Pancreta Bank S A, Greece',
    image: 'Antonios.jpg',
    companyImage: 'pancreta.png',
    link: 'https://www.linkedin.com/in/antonios-vourakis-7639249/?originalSubdomain=gr',
  },
  {
    name: 'Pol Navarro',
    title: 'Chief Executive Officer',
    company: 'InnoCells, Digital hub BancoSabadell',
    image: 'pol.jpg',
    companyImage: 'sabadell.png',
    link: '',
  },
  {
    name: 'Martin Sladecek',
    title: 'Director of Digital Strategic Studies',
    company: 'Société Générale',
    image: 'Sladecek.jpg',
    companyImage: 'sg.png',
    link: 'https://www.linkedin.com/in/sladecek/',
  },
  {
    name: 'Kerim Alain Bertrand',
    title: 'Chief Sales Officer (CSO) – Global',
    company: 'SmartMessage',
    image: 'kerim.jpg',
    companyImage: 'smart.png',
    link: 'https://www.linkedin.com/in/kerimalainbertrand/',
  },
  {
    name: 'Evans Munyuki ',
    title:
      'Award Winning Chief Digital Officer| Author Of 5 Star rated book: Up For Digital Transformation in the Gig Economy',
    company: '',
    image: 'evans.jpg',
    companyImage: undefined,
    link: 'https://www.linkedin.com/in/evansmunyuki/',
  },

  {
    name: 'Mike Cunningham',
    title: 'Chief Strategy & Digital Officer -Strategy & Digital Group',
    company: 'Banque Saudi Fransi, Saudi Arabia',
    image: 'mike.jpg',
    companyImage: 'saudi.jpg',
    link: 'https://www.linkedin.com/in/gomikecunningham/?originalSubdomain=sa',
  },
  {
    name: 'Peter Rossiter ',
    title: 'Chief Risk Officer',
    company: 'Starling International, Ireland',
    image: 'peter.png',
    companyImage: 'starling.jpg',
    link: 'https://www.linkedin.com/in/peter-rossiter-a9782912/',
  },
  {
    name: 'Gurhan Cam',
    title:
      'Senior Vice President & Deputy CDO – Digital Transformation & Digital Banking & Innovation',
    company: 'Deniz Bank,Turkey',
    image: 'gurham.jpg',
    companyImage: 'deniz.png',
    link: 'https://www.linkedin.com/in/gurhancam/',
  },
  {
    name: 'Meital Raviv',
    title:
      'Executive Vice President, Head of Digital Strategy, Open Banking and Fintech innovation',
    company: 'Bank Hapoalim, Israel',
    image: 'meitel.jpg',
    companyImage: 'hapolim.png',
    link: 'https://www.linkedin.com/in/meital-raviv-b35bb212/?originalSubdomain=il',
  },
  {
    name: 'Jelena Zelenovic Matone',
    title: 'Chief Information Security Officer',
    company: 'European Investment Bank, Luxembourg',
    image: 'jelena.jpg',
    companyImage: 'eib.png',
    link: 'https://www.linkedin.com/in/lance-mambondiani-32452055/',
  },
  {
    name: 'Martins Berzins',
    title: 'Head of Digital Customer Experience',
    company: 'Citadele banka, Latvia',
    image: 'martins.jpg',
    companyImage: 'citadele.png',
    link: 'https://www.linkedin.com/in/mberzins/?originalSubdomain=lv',
  },
  {
    name: 'Khanim Rustamova',
    title: 'Head of Digital SME Cash Management',
    company: 'PASHA Bank, Azerbaijan',
    image: 'khanim.jpg',
    companyImage: 'pasa.png',
    link: 'https://www.linkedin.com/in/mberzins/?originalSubdomain=lv',
  },
  {
    name: 'Tomas Stegura',
    title: 'Group Director Digitalization & Architecture ',
    company: 'CSOB Československá obchodní banka, Czech Republic',
    image: 'steruga.jpg',
    companyImage: 'csob.jpg',
    link: 'https://www.linkedin.com/in/tomasstegura/?originalSubdomain=cz',
  },
  {
    name: 'Melissa Tuozzolo',
    title: 'Head of Payments Financial Market Infrastructures and Industry Initiatives ',
    company: 'CitiBank, USA',
    image: 'melissa.jpg',
    companyImage: 'citi.png',
    link: '',
  },

  // {
  //   name: "Ziv Gafni",
  //   title:
  //     "Executive Director, Head of Digital Strategy, Fintech & Markets Innovation",
  //   company: "J.P. Morgan, Israel",
  //   image: "ziv.jpg",
  //   companyImage: "jp.jpg",
  //   link: "https://www.linkedin.com/in/ziv-gafni/",
  // },
  {
    name: 'Murat Guner',
    title: 'Regional Account Manager - MEA',
    company: 'SmartMessage',
    image: 'murat.jpg',
    companyImage: 'smart.png',
    link: 'https://www.linkedin.com/in/gunermurat/',
  },

  {
    name: 'Constantin Mareș',
    title: 'Chief Digital Officer',
    company: 'OTP Bank, Romania',
    image: 'costantine.jpg',
    companyImage: 'otp.png',
    link: 'https://www.linkedin.com/in/constantin-mare%C8%99-9b6278/',
  },

  {
    name: 'Becky Clements',
    title: 'Director of Payments',
    company: 'UK Finance',
    image: 'becky.png',
    companyImage: 'uk.jpg',
    link: 'https://www.linkedin.com/in/becky-clements-b186a098/',
  },

  {
    name: 'Pierre-Alexandre Boulay',
    title: 'Head of CEE',
    company: 'Backbase, Netherlands',
    image: 'pierre.jpg',
    companyImage: 'backbase.jpeg',
    link: 'https://www.linkedin.com/in/boulay1/?originalSubdomain=nl',
  },
  {
    name: 'Mikhail Hryshchenko',
    title: 'Regional Sales Director EMEA & APAC',
    company: 'Creatio',
    image: 'Mikhail.png',
    companyImage: 'creatio.jpg',
    link: '',
  },
];
