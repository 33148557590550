import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Particles from "./Particles";

function Event() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          background: "#5a2169",
          backgroundColor: "#5a2169",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflow: "hidden",
        }}
        className='section section-with-space'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12} className='p-4'>
              <h1 className='text-center pb-0 mt-0'>
                <span className='text-700 text-g'> AT THE EVENT</span>
              </h1>
              <h5 className='text-400 text-white'>
                We are bringing a new virtual format where we will have virtual
                event platform to make sure you have the best networking
                opportunities possible at Finnovex Europe.
              </h5>
            </Col>
          </Row>
          <Row className='text-700' style={{ marginTop: "2rem" }}>
            <Col lg={6}>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  src='https://www.youtube.com/embed/iCk-z6evJ1k'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='iCk-z6evJ1k'
                ></iframe>
              </div>
              <br />
              <h1
                className=' text-white pb-3'
                style={{
                  borderBottom: "5px solid #bdd633",
                }}
              >
                <span className='text-g'> WHY ATTEND?</span>
              </h1>

              <h5 className='text-400 pt-2 text-white'>
                You would learn how to evaluate, deploy, use, and customize the
                financial technologies to improve business processes. You will
                also gain first-hand insight from customers on the challenges
                they face across the entire value chain of financial processes.
              </h5>
              <Button
                href='/register'
                className='btn my-2 text-center px-5'
                color='primary'
                size='md'
              >
                REGISTER
              </Button>
            </Col>
            <Col lg={6}>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe
                  src='https://www.youtube.com/embed/nKU36f1cwmw'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  title='nKU36f1cwmw'
                ></iframe>
              </div>
              <br />
              <h1
                className=' text-white pb-3'
                style={{
                  borderBottom: "5px solid #bdd633",
                }}
              >
                <span className='text-g'> WHY SPONSOR?</span>
              </h1>

              <h5 className='text-400 text-white pt-2'>
                Sponsoring Finnovex Europe comes with many benefits including a
                customized experience which helps you highlight your brand in
                the spotlight and above competition through a simple 3-Step
                process.
              </h5>
              <Button
                href='/partners'
                className='btn my-2 text-center px-5'
                color='primary'
                size='md'
              >
                BECOME A SPONSOR
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Event;
