import React from 'react';
import { Button, Container, Row, Col, Input, Form, Alert, Spinner } from 'reactstrap';

function Register() {
	const pageSize = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? '5rem' : '1rem';
	const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth > 1024 ? true : false;
	const [details, setDetails] = React.useState({
		subject: "Finnovex EU Award Nomination",
		source: "FINNOVEXSA",
	  });
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	document.documentElement.classList.remove('nav-open');
	React.useEffect(() => {
		document.body.classList.add('register-page');
		return function cleanup() {
			document.body.classList.remove('register-page');
		};
	});

	const onChangeDetails = e => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};

	const onSubmitForm = e => {
		e.preventDefault();
		setLoading(true);
		setError(false);
		setSuccess(false);
		fetch('https://us-central1-finnosec.cloudfunctions.net/api/emailawards', {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(details),
		})
			.then(res => {
				console.log(res);
				if (res.ok) return res;
				else throw new Error(res);
			})
			.then(() => {
				setLoading(false);
				setSuccess("Thank you for your request. We'll get back to you shortly.");
			})
			.catch(err => {
				setLoading(false);
				setError('Something went wrong please try again');
			});
	};
	return (
		<div
			style={{
				padding: `${pageSize} 0`,
				backgroundImage: 'url(' + require('assets/images/awardsbackgroundpage.png') + ')',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<Container className="my-auto text-white">
				<Form onSubmit={onSubmitForm} className="register-form">
					<Row>
						<Col lg={12}>
							<h1
								className="text-700 text-white text-center"
								style={{ fontSize: size ? '9.35rem' : '3rem' }}
							>
								FINNOVEX
							</h1>
						</Col>
						<Col lg={12}>
							<h1 className="text-700 p-3 text-primary text-center">AWARDS</h1>
						</Col>
						<Col lg={6}>
							<label>Award Category*</label>
							<Input type="select" name="category" id="" onChange={onChangeDetails} required>
								<option value="">Select</option>
								<option>Excellence in Mobile Banking</option>
								<option>Excellence in Digital Banking</option>
								<option>Excellence in Payments</option>
								<option>Excellence in Fintech</option>
								<option>Excellence in Customer Experience Enhancement</option>
								<option>Excellence in Insurance Digitization</option>
								<option>Outstanding Contribution to Strategic National Development Initiatives</option>
								<option>Outstanding Contribution to Fintech Initiatives</option>
								<option>Finnovator of the Year</option>
								<option>Finfluencer of the Year</option>
								<option>CEO of the Year</option>
								<option>COO of the Year</option>
								<option>CDO of the Year</option>
								<option>CIO of the Year</option>
								<option>CTO of the Year</option>
							</Input>
						</Col>
						<Col lg={6}>
							<label>Reason for Nomination*</label>
							<Input placeholder="Reason" type="text" name="reason" onChange={onChangeDetails} required />
						</Col>
						<Col lg={12}>
							<h4 className="text-700 pb-1 pt-5 text-primary text-center">NOMINEE’S INFORMATION</h4>
						</Col>
						<Col lg={6}>
							<label>Nominee Name*</label>
							<Input
								placeholder="Name"
								type="text"
								name="nomineeName"
								onChange={onChangeDetails}
								required
							/>
						</Col>

						<Col lg={6}>
							<label>Nominee Phone (with country code)*</label>
							<Input
								placeholder="Phone"
								type="text"
								name="nomineePhone"
								onChange={onChangeDetails}
								required
							/>
						</Col>

						<Col lg={12}>
							<label>Nominee's E-mail Address (official email only) *</label>
							<Input
								placeholder="Email"
								type="text"
								name="nomineeEmail"
								onChange={onChangeDetails}
								required
							/>
						</Col>

						<Col lg={12}>
							<h4 className="text-700 pb-1 pt-5 text-primary text-center">YOUR INFORMATION</h4>
						</Col>
						<Col lg={6}>
							<label>Name*</label>
							<Input placeholder="Name" type="text" name="name" onChange={onChangeDetails} required />
						</Col>

						<Col lg={6}>
							<label>Phone (with country code)*</label>
							<Input placeholder="Phone" type="text" name="phone" onChange={onChangeDetails} required />
						</Col>

						<Col lg={12}>
							<label>E-mail Address (official email only)*</label>
							<Input placeholder="Email" type="text" name="email" onChange={onChangeDetails} required />
						</Col>
					</Row>
					<br />
					<Button
						block
						className="btn text-white text-700"
						color="primary"
						size="lg"
						type="submit"
						disabled={loading}
					>
						Submit
						{loading ? (
							<span>
								{' '}
								<Spinner color="warning" size="sm" />
							</span>
						) : (
							''
						)}
					</Button>
				</Form>
			</Container>
			{error ? (
				<Alert color="danger" isOpen={true} fade={true} className="text-center" style={{ marginTop: '1rem' }}>
					{error}
				</Alert>
			) : (
				''
			)}
			{success ? (
				<Alert color="success" isOpen={true} fade={true} className="text-center" style={{ marginTop: '1rem' }}>
					{success}
				</Alert>
			) : (
				''
			)}
		</div>
	);
}

export default Register;
