import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
const size =
  document.body.clientWidth >= 1024 ||
  document.documentElement.clientWidth > 1024
    ? true
    : false;
function Spotlight() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          // background: "url(" + require(`assets/images/bg6.png`) + ")",
          background: "#5a2169",
          backgroundAttachment: "fixed",
          backgroundColor: "#5a2169",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top",
          overflow: "hidden",
        }}
        className='section'
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row style={{}} className='justify-content-center'>
            <Col lg={8} className='p-4'>
              <h1 className='text-center pt-1 text-700 text-g'>
                Finnovex Topic Outline
              </h1>

              <br />
            </Col>
          </Row>
          <Row
            className='text-700 justify-content-center'
            style={{ marginTop: "1rem" }}
          >
            {content.map((data, index) => (
              <Col lg={4} key={index} className='py-2'>
                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card style={{ background: "transparent" }}>
                    <Container fluid>
                      <Row>
                        <Col
                          xs={3}
                          className=' py-3'
                          style={{ borderRight: "3px solid #ee3388" }}
                        >
                          <img
                            src={require(`assets/images/icons/${data.image}`)}
                            alt=''
                            width='100%'
                            style={{ maxWidth: "100px" }}
                          />
                        </Col>
                        <Col xs={9} className='align-self-center text-white'>
                          <h5 className='text-400 m-0'>{data.title}</h5>
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Spotlight;

export const content = [
  { title: `Open Banking Finance`, image: "66.png" },
  {
    title: `Conversational Banking Platform With Big Data And Analytics`,
    image: "70.png",
  },

  {
    title: `Enabling Entity Based Regulation For Fintechs In Europe`,
    image: "68.png",
  },
  {
    title: `Role Of Fintech In Sustainable Banking And ESG`,
    image: "73.png",
  },
  {
    title: `Cross Border Payment And Globalization Of Banking Processes`,
    image: "61.png",
  },
  {
    title: `Blockchain Finance: Building A Decentralized Financial System`,
    image: "60.png",
  },

  {
    title: `Embedded Finance & Agile Transformation In Banking`,
    image: "67.png",
  },

  {
    title: `Neobanks -Enabling All-In-One Banking Service`,
    image: "63.png",
  },

  {
    title: `New banking ecosystem – a game changer for open banking models`,
    image: "69.png",
  },
  {
    title: `New Anti-Money Laundering (AML) Guide For Digital Banks`,
    image: "62.png",
  },

  {
    title: `Super App Era For Banking Services`,
    image: "64.png",
  },

  {
    title: `Responding to Changing customer behaviours: Right offer, right time, right channel`,
    image: "71.png",
  },

  // {
  //   title: `Insurance Beyond
  //   Digital: The Rise
  //   of Ecosystems and
  //   Platforms`,
  //   image: "72.png",
  // },
];
