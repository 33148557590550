import React from "react";
import { Col, Container, Row } from "reactstrap";

function AwardsPage() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  return (
    <>
      <div
        style={{
          overflow: "hidden",
          background: "#fff",
        }}
        className='section pb-2'
      >
        <Container fluid>
          <Container>
            <Row>
              <Col lg={6} className={`align-self-center`}>
                <h4 className=' text-400 '>
                  <b>Exibex</b> is committed to recognising the efforts of the
                  finest, the brightest and the boldest in their respective
                  fields. We salute risk-takers, trendsetters and pacesetters
                </h4>
                <br />

                <h4 className=' text-400'>
                  <b>Finnovex Awards</b> have been constituted to recognise and
                  appreciate the effort of various individuals, institutions and
                  service providers in the field of financial services. We seek
                  to honour the pioneers and visionaries who have transformed
                  the financial services industry.
                </h4>
                <br />

                <h4 className=' text-400'>
                  <b>Finnovex</b> as a brand resonates and revolves around the
                  best and the next innovations that enable excellence.{" "}
                  <b>Finnovex Awards</b> cherish and celebrate outstanding
                  achievements in the banking and finance industry. They are
                  aimed at honouring organisations and individuals, that have
                  consistently demonstrated exemplary performance. These awards
                  set a standard of excellence that is held in high regard. They
                  aim to serve as an effective platform for financial institutes
                  to showcase their talent and identify leaders within the
                  industry. <b>Finnovex Awards</b> aims to serve as an effective
                  platform for financial institutes to showcase their talent and
                  identify leaders within the industry. It not just recognises
                  the endeavours of the most successful banking & financial
                  organisations, but will also set the tone for
                  policy-influencing within the banking and finance industry and
                  inspire other organisations to attain their own goals.
                </h4>
                <br />
              </Col>
              <Col lg={6} className={`mb-3 align-self-center`}>
                <img
                  src={`https://drive.google.com/thumbnail?authuser=0&sz=w500&id=1SdiW9k6RfDU9xT7NHMeDIg4IUBx0_HiI`}
                  width='100%'
                  alt='awards'
                  style={{
                    borderRight: "10px solid #47c2d8",
                    borderTop: "10px solid #47c2d8",
                  }}
                />
                <Container>
                  <br />
                  <h2 className='text-700 p-0 m-0'>CATEGORIES</h2>
                  <Row className='border p-2'>
                    <Col lg={12} className='px-0'>
                      <ul
                        style={{
                          listStyleImage: `url(${require("assets/images/icons/next.png")}) `,
                        }}
                      >
                        {content.map((c) => (
                          <li key={c}>
                            <h6 className='text-700'>{c}</h6>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </Col>

              <Col lg={12} className='mt-3 '></Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default AwardsPage;

const content = [
  "Excellence in Mobile Banking",
  "Excellence in Digital Banking",
  "Excellence in Fintech- Payments",
  "Excellence in Fintech- Digital Onboarding",
  "Excellence in Fintech- Security",
  "Excellence in Fintech -Customer Experience Enhancement",
  "Excellence in InsurTech",
  "Excellence in RegTech",
  "Outstanding Contribution to Strategic National Development Initiatives",
  "Outstanding Contribution to Fintech Initiatives",
  "Finnovator of the Year",
  "Finfluencer of the Year",
  "CEO of the Year",
  "COO of the Year",
  "CDO of the Year",
  "CIO of the Year",
  "CTO of the Year",
];
